/** @format */

import { useEffect, useState } from "react";
import { IoPricetagSharp } from "react-icons/io5";
import { ITagType } from "../../Api/Interfaces";
import { CrudList } from "../../Shared/CrudList/CrudList";
import { IItem } from "../../Shared/List/Interfaces";
import "./TagTypeView.css";
import { TagTypeService } from "../../Api/TagType";

export const TagTypeView = () => {
    const [tagTypes, setTagTypes] = useState<ITagType[]>([]);

    const updateTagTypes = async () => {
        const tags = await TagTypeService.ListAsync();
        setTagTypes(tags);
    };

    useEffect(() => {
        updateTagTypes();
    }, []);

    const listCompatiableItems: IItem[] = [];
    tagTypes.forEach(tag => {
        const item: IItem = {
            id: tag.id,
            name: tag.name,
        };

        listCompatiableItems.push(item);
    });

    return (
        <div className="AdminListSimpleView">
            <div className="info">
                <h1>Tagg-typer</h1>
                <p><b>Vær forsiktig med og endre</b>, da dette vil føre til endring i produksjon. Bedre og lage en ny enn og redigere</p>
            </div>
            <CrudList
                crudComponent={item => (
                    <div className="inputGroup" style={{ width: "100%" }}>
                        <input autoFocus required name="name" placeholder="Navn" defaultValue={item?.name} />
                        <IoPricetagSharp />
                    </div>
                )}
                onCreate={async e => {
                    await TagTypeService.CreateAsync(e.name.value);
                    updateTagTypes();
                }}
                onEdit={async e => {
                    await TagTypeService.RenameAsync(e.id.value, e.name.value);
                    updateTagTypes();
                }}
                onDelete={async id => {
                    const confirmation = window.confirm("Sikker på at du vil slette tag-typen? Alle taggene som er tilkoblet denne typen vil havne under diverse kategorien.");
                    if (confirmation) {
                        await TagTypeService.DeleteAsync(id);
                        updateTagTypes();
                    }
                }}
                items={listCompatiableItems}
            />
        </div>
    );
};
