/** @format */

import { IoTrashOutline } from "react-icons/io5";
import { IAdminUser, UserType } from "../../Api/Interfaces";
import "./SingleUser.css";
import { TbReportMoney } from "react-icons/tb";
import { FaUserShield } from "react-icons/fa";
import { useState } from "react";
import { useModal } from "../../Shared/Modal/UseModal";
import { UserService } from "../../Api/User";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";

export interface ISingleUser {
    user: IAdminUser;
    isSelected: boolean;
    reloadUsers: () => any;
}

export const SingleUser = (props: ISingleUser) => {
    const userTypeModal = useModal("Endre brukertype");

    const deleteUser = async () => {
        const letterConfirm =
            window.prompt(
                `ADVARSEL! Dette vil slette bruker: ${props.user.name} og all info tilknyttet. Er du sikker skriv inn de 3 første bokstavene i navnet:`
            ) ?? "";
        if (letterConfirm !== props.user.name.substring(0, 3)) return;

        await UserService.AdminDeleteAsync(props.user.id);

        alert("BRUKER SLETTET");
        props.reloadUsers();
    };

    const changeQuantity = async () => {
        const quantityTxt = window.prompt(`Antall brukere som kan være logget inn samtidig:`);
        if (quantityTxt === null) {
            return;
        }

        var quantity = parseInt(quantityTxt ?? "");
        if (isNaN(quantity)) {
            alert("Ikke et gyldig tall");
            return;
        }

        if (quantity < 0) {
            alert("Må være et positivt tall");
            return;
        }

        await UserService.AdminSetQuantityAsync(props.user.id, quantity);

        alert("ENDRET ANTALL");
        props.reloadUsers();
    };

    const changeUserType = async () => {
        let typeTxt =
            window.prompt(
                `Brukertype for bruker ${props.user.name}. Husk at adminbrukere har alle rettigheter. Vær forsiktig. Skriv inn enten: normal, test, eller admin:`
            ) ?? "";
        typeTxt = typeTxt.trim().toLowerCase();
        const allowedTypes = ["normal", "test", "admin"];
        if (!allowedTypes.includes(typeTxt)) return;

        let type = UserType.normal;
        if (typeTxt === "admin") type = UserType.admin;
        if (typeTxt === "test") type = UserType.test;

        await UserService.AdminSetTypeAsync(props.user.id, type);

        alert("BRUKERTYPE ENDRET");
        props.reloadUsers();
    };

    const changePrice = async () => {
        const price = window.prompt(`Egendefinert pris på bruker: ${props.user.name}. Ikke skriv inn noe om du vil sette tilbake til standard priser. Stripe pris id:`);
        if (price === null) return;

        const success = await UserService.AdminSetCustomPriceAsync(props.user.id, price);
        if (!success) return alert("Error! Sjekk at det er en gyldig pris")

        if (props.user.quantity === 0) {
            await UserService.AdminSetQuantityAsync(props.user.id, 10000);
            alert("Antall samtidige bruker satt til 10000 så det ikke blir glemt");
        }

        alert("PRIS SATT");
        props.reloadUsers();
    };

    const shouldFollowUp = props.user.type === UserType.normal && !props.user.isPaid;
    const specialUser = props.user.type === UserType.test || props.user.type === UserType.admin;

    return (
        <div className="SingleUser">
            {userTypeModal.component}

            <div className={`customerItem ${props.isSelected && "selected"} ${shouldFollowUp && "followup"} ${specialUser && "specialUser"}`}>
                <div className="mainContent">
                    <div className="left">
                        <h2>{props.user.name}</h2>
                        <h3>{props.user.email}</h3>
                    </div>

                    <div className="right">
                        <p>{UserType[props.user.type]}</p>
                        {props.user.type === UserType.normal && !props.user.isPaid && <p style={{ color: "var(--error-color)" }}>Har ikke betalt</p>}

                        {props.user.customPrice.trim() !== "" && <p>{props.user.customPrice}</p>}

                        {props.user.type === UserType.normal && <p>Antall: {props.user.quantity}</p>}
                    </div>
                </div>

                <div className="selected btnGroup" onClick={(e) => e.stopPropagation()} style={{ display: props.isSelected ? "flex" : "none" }}>
                    <button
                        onClick={() => {
                            changeUserType();
                        }}
                    >
                        <FaUserShield />
                        Brukertype
                    </button>

                    {props.user.type === UserType.normal && (
                        <>
                            <button onClick={changeQuantity}>
                                <MdOutlineProductionQuantityLimits />
                                Maks antall
                            </button>

                            <button onClick={changePrice}>
                                <TbReportMoney />
                                Egendefinert pris
                            </button>
                        </>
                    )}

                    <button onClick={deleteUser}>
                        <IoTrashOutline style={{ color: "red" }} />
                        Slett
                    </button>
                </div>
            </div>
        </div>
    );
};
