
import { Connector } from "./Connector";
import { ITag } from "./Interfaces";

export const TagService = {
    async ListAsync(): Promise<ITag[]> {
        const data = await Connector.getAsync("tag")
        if (!data.ok) return [];
        return data.content
    },

    async GetAsync(id: number): Promise<ITag | null> {
        const data = await Connector.getAsync(`tag/${id}`)
        if (!data.ok) return null;
        return data.content
    },

    async CreateAsync(name: string): Promise<number | null> {
        const data = await Connector.postAsync("tag", { name, });
        if (!data.ok) return null;
        return data.content.id;
    },

    async UpdateAsync(id: number, name: string, type: number | null): Promise<number | null> {
        let obj: any = { name, }
        if (type) {
            obj = { name, type, }
        }

        const data = await Connector.putAsync(`tag/${id}`, obj)
        if (!data.ok) return null;
        return data.content.id
    },

    async DeleteAsync(id: number): Promise<boolean> {
        const data = await Connector.deleteAsync(`tag/${id}`);
        return data.ok
    }
}