/** @format */

import { useNavigate, useParams } from "react-router-dom";
import styles from "./CreateEditCustomerModal.module.css";
import { CustomerEdit } from "./CustomerEdit/CustomerEdit";
import { useContext, useEffect, useState } from "react";
import { IVisiblePlan, StateManContext } from "../../StateMan";
import { ICustomer } from "../../../../Api/Interfaces";
import { CustomerPlanList } from "./CustomerPlanList/CustomerPlanList";
import FocusTrap from "focus-trap-react";

export const CreateEditCustomerModal = () => {
    const params = useParams();
    const stateMan = useContext(StateManContext);
    const [currentCustomer, setCurrentCustomer] = useState<ICustomer>();
    const [plansOnCustomer, setPlansOnCustomer] = useState<IVisiblePlan[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        loadCustomerAsync();
    }, [stateMan?.plans, stateMan?.customers]);

    const loadCustomerAsync = async () => {
        var customerId = parseInt(params.customerId ?? "");
        if (isNaN(customerId) || customerId === 0) return;
        const customers = stateMan?.customers ?? [];

        const customer = customers.find((c) => c.id === customerId);
        if (customer) {
            setCurrentCustomer(customer);
            loadPlansAsync(customer);
        }
    };

    const loadPlansAsync = async (customer: ICustomer) => {
        const plans = stateMan?.plans ?? [];
        const filteredPlans = plans.filter(p => p.customer?.id === customer.id);
        setPlansOnCustomer(filteredPlans)
    };

    const onKeyUp = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
            navigate("/customers");
        }
    };

    useEffect(() => {
        document.addEventListener("keyup", onKeyUp)
        return () => {
            document.removeEventListener("keyup", onKeyUp)
        }
    }, [])

    return (
        <FocusTrap>
            <div className={styles.wrapper}>
                <div className={styles.modal}>
                    <div className={`${styles.section} ${styles.editCustomerSection}`}>
                        <CustomerEdit exitModal={() => navigate("/customers")} customer={currentCustomer} />
                    </div>

                    {params.customerId && (
                        <div className={`${styles.section} ${styles.planListSection}`}>
                            <CustomerPlanList customer={currentCustomer} plans={plansOnCustomer} />
                        </div>
                    )}
                </div>

                <div />
            </div>
        </FocusTrap>
    );
};
