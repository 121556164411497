/** @format */

import { useEffect, useState } from "react";
import { List } from "../List/List";
import { UseSearch } from "../Search/UseSearch";
import "./CrudList.css";
import { ICrudListProps } from "./Interfaces";

export const CrudList = (props: ICrudListProps) => {
    let search = UseSearch(props.items);
    const [isCreateMenuOpen, setIsCreateMenuOpen] = useState(false);

    useEffect(() => {
        search.refresh(props.items);
    }, [props.items]);

    useEffect(() => {
        search.clear();
    }, [props.clearSearchCounter])

    const onCreate = () => {
        if (props.customCreateAction) {
            props.customCreateAction();
        } else {
            setIsCreateMenuOpen(true);
        }
    };

    return (
        <div className="CrudList">
            <div className="searchBox">
                {search.component}
                <button className="Primary Button" onClick={onCreate}>
                    Opprett
                </button>
            </div>

            {props.afterSearchComponent}

            {isCreateMenuOpen ? (
                <form
                    onKeyDown={(e) => {
                        if (e.key === "Escape") {
                            setIsCreateMenuOpen(false);
                        }
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (props.onCreate) props.onCreate(e.target);

                        const sender: any = e.target;
                        const inputs: any[] = sender.querySelectorAll("input:not(.formInput)");
                        inputs.forEach((input) => {
                            input.value = "";
                        });
                        setIsCreateMenuOpen(false);
                    }}
                >
                    <div className="ListItem">
                        <div className="componentHolder">{props.crudComponent ? props.crudComponent() : null}</div>

                        <input type="submit" style={{ display: "none" }} value="enterSubmit" />
                        <div className="btnGroup">
                            <button
                                className="Secondary Small Button"
                                onClick={() => {
                                    setIsCreateMenuOpen(false);
                                }}
                            >
                                Avbryt
                            </button>
                            <input type="submit" className="Primary Small Button NoShadow formInput" value="Lagre" />
                        </div>
                    </div>
                </form>
            ) : null}

            <List
                {...props}
                items={search.returnedItems}
            />
        </div>
    );
};
