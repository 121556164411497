/** @format */

import { useContext, useEffect } from "react";
import { UserService } from "../../../Api/User";
import { UserContext } from "../../../App";
import { UseLoading } from "../../../Shared/Loading/UseLoading";
import "./PaymentValidation.css";

export interface IPaymentValidationProps {
    message: string;
    portalUrl: string;
}

export const PaymentValidation = (props: IPaymentValidationProps) => {
    const useActionLoader = UseLoading();
    const user = useContext(UserContext);

    const logOut = async () => {
        if (!user) return;
        useActionLoader.startLoading();
        await UserService.LogoutAsync();
        user.checkUserLogin();
        useActionLoader.stopLoading();
    };

    const openPaymentPortal = () => {
        if (!props.portalUrl) {
            return alert("Klarte ikke åpne betalingsportal, prøv igjen senere")
        }

        useActionLoader.startLoading();
        window.open(props.portalUrl, "_self");
        useActionLoader.stopLoading();
    };

    return (
        <div className="PaymentValidation">
            <h1>Oi, her er det noe galt</h1>
            <p>{props.message}</p>

            <div className="btns">
                {useActionLoader.loadingComponent}

                <button disabled={useActionLoader.isLoading} className="Button Secondary" onClick={logOut}>
                    Logg ut
                </button>

                <button style={{ marginLeft: 20 }} disabled={useActionLoader.isLoading} className="Button Primary" onClick={openPaymentPortal}>
                    Åpne betalingsportal
                </button>
            </div>
        </div>
    );
};
