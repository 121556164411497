/** @format */

import { CircleAlert, OctagonX } from "lucide-react";
import { PrettyDetails } from "../../../../Shared/PrettyDetails/PrettyDetails";
import styles from "../SettingSection.module.css";
import { useContext } from "react";
import { UserContext } from "../../../../App";
import { UseLoading } from "../../../../Shared/Loading/UseLoading";
import { UserService } from "../../../../Api/User";

export const Dangerzone = () => {
    const user = useContext(UserContext);
    const loader = UseLoading();

    const deleteUser = async () => {
        const password = window.prompt(
            "Er du sikker på at du vil slette brukeren din? Dette vil si opp betalingsmåte, og slette all data tilknyttet deg. Det er ingen måte og reversere dette på. Hvis du er helt sikker så skriv inn bruker-passordet ditt for å bekrefte:"
        );
        if (!password) return;

        const shouldDelete = window.confirm("ER DU HELT SIKKER?");
        if (!shouldDelete) return;

        loader.startLoading();
        const success = await UserService.DangerouslyDeleteUserAsync(password);
        loader.stopLoading();
        if (!success) return alert("Klarte ikke slette bruker, skrev du inn riktig passord?");

        alert("Bruker slettet!");
        user?.checkUserLogin();
    };

    return (
        <PrettyDetails text={"Faresone"} icon={<CircleAlert />} extraTopContent={<>{loader.loadingComponent}</>}>
            <div className={styles.inputSection}>
                <div className={styles.infoText}>
                    <h2>Slett bruker</h2>
                    <p>Dette vil slette alle planer, kunder, og si opp betaling. Ingen PDF linker eller e-poster vil lengre fungere.</p>
                </div>

                <button className={`${styles.settingsBtn} ${styles.deleteUserBtn}`} disabled={loader.isLoading} onClick={deleteUser}>
                    Slett bruker
                </button>
            </div>
        </PrettyDetails>
    );
};
