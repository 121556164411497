/** @format */

import { Building2, House, Mail } from "lucide-react";
import { PrettyDetails } from "../../../../Shared/PrettyDetails/PrettyDetails";
import styles from "../SettingSection.module.css";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../App";
import { EmailValidator } from "../../../../Shared/EmailValidator";
import { UseLoading } from "../../../../Shared/Loading/UseLoading";
import { UserService } from "../../../../Api/User";
import { ImageUpload } from "../ImageUpload/ImageUpload";

export const GeneralInfo = () => {
    const user = useContext(UserContext);
    const loader = UseLoading();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [icon, setIcon] = useState("");

    const resetUserInfo = () => {
        setName(user?.name ?? "");
        setEmail(user?.email ?? "");
        setIcon(user?.icon ?? "");
    };

    const saveUserInfo = async () => {
        loader.startLoading();
        const success = await UserService.UpdateAsync(name, email, icon);
        loader.stopLoading();

        if (!success) {
            alert("Klarte ikke lagre brukerinfo. Dette kan være fordi e-posten allerede er i bruk.")
            resetUserInfo();
        }

        user?.checkUserLogin();
    }

    useEffect(() => {
        resetUserInfo();
    }, [user?.name, user?.email, user?.icon]);

    const isValidEmail = email.length === 0 || EmailValidator.isValidEmailAddress(email);
    const areButtonsVisible = (name !== user?.name || email !== user?.email || user?.icon !== icon)
    const canSaveBasicInfo = name.length > 0 && areButtonsVisible && isValidEmail;

    return (
        <PrettyDetails
            text={"Generelt"}
            icon={<Building2 />}
            extraTopContent={
                <div className={styles.actionButtons}>
                    {loader.loadingComponent}

                    {areButtonsVisible && (
                        <>
                            <button disabled={loader.isLoading} className={`${styles.actionBtn} ${styles.cancelBtn}`} onClick={resetUserInfo}>
                                Avbryt
                            </button>
                            <button disabled={!canSaveBasicInfo || loader.isLoading} className={`${styles.actionBtn} ${styles.saveBtn}`} onClick={saveUserInfo}>Lagre</button>
                        </>
                    )}
                </div>
            }
        >
            <div className={styles.inputSection}>
                <div className={styles.infoText}>
                    <h2>Firmanavn<span style={{ color: "red" }}> *</span></h2>
                    <p>Navnet vises i toppmenyen og på PDFer</p>
                </div>

                <div className={`borderInputIcon ${styles.input}`}>
                    <House />
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
            </div>

            <hr />

            <div className={styles.inputSection}>
                <div className={styles.infoText}>
                    <h2>E-post<span style={{ color: "red" }}> *</span></h2>
                    <p>Dette må være en gyldig e-post addresse</p>
                </div>

                <div className={`borderInputIcon ${styles.input}`}>
                    <Mail style={{ color: isValidEmail ? undefined : "red" }} />
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
            </div>

            <hr />

            <div className={styles.inputSection}>
                <div className={styles.infoText}>
                    <h2>Ikon</h2>
                    <p>Ikonet vises i toppmenyen og på PDFer</p>
                </div>

                <ImageUpload image={icon} onImageChanged={setIcon} />
            </div>
        </PrettyDetails>
    );
};
