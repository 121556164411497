/** @format */

import styles from "./Filters.module.css";
import { useState } from "react";
import { IoHeart, IoHeartOutline, IoSearch } from "react-icons/io5";
import { SelectType, UseSelect } from "../../../../Shared/UseCustomerSelect";
import { GenericSearchEngine } from "../../../../Shared/Search/GenericSearchEngine";
import { IVisiblePlan } from "../../StateMan";
import multiOption from "../../SharedStyles/MultiOption.module.css"

enum OrderingOption {
    Newest,
    Oldest,
    LastChanged,
}

export const usePlanFilter = (allPlans: IVisiblePlan[]) => {
    const [searchText, setSearchText] = useState("");
    const [onlyFavorites, setOnlyFavorites] = useState(false);
    const [ordering, setOrdering] = useState<OrderingOption>(OrderingOption.LastChanged);

    const customerSelect = UseSelect(SelectType.customer);

    const element = (
        <div className={styles.container}>
            <div className={styles.filtering}>
                <div className={`UseSearch ${styles.searchBox}`}>
                    <IoSearch />
                    <input placeholder="Søk" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                </div>

                {customerSelect.component}

                <button
                    className={`favoritesBtn ${onlyFavorites ? "favoritesBtnSelected" : ""}`}
                    onClick={() => setOnlyFavorites(!onlyFavorites)}
                >
                    {onlyFavorites ? <IoHeart size={18} color="var(--favorite-color)" /> : <IoHeartOutline size={18} />}
                    Favoritter
                </button>
            </div>

            <div className={styles.ordering}>
                <div className={multiOption.container}>
                    <button data-selected={ordering === OrderingOption.LastChanged} onClick={() => setOrdering(OrderingOption.LastChanged)}>
                        Sist endret
                    </button>

                    <button data-selected={ordering === OrderingOption.Newest} onClick={() => setOrdering(OrderingOption.Newest)}>
                        Nyeste
                    </button>

                    <button data-selected={ordering === OrderingOption.Oldest} onClick={() => setOrdering(OrderingOption.Oldest)}>
                        Eldste
                    </button>
                </div>
            </div>
        </div>
    );

    const planSearchEngine = new GenericSearchEngine<IVisiblePlan>({ items: allPlans, fieldsToSearch: ["name"] });
    const planSearchItems = planSearchEngine.Search(searchText);

    let filteredPlans = planSearchItems.filter((p) => {
        if (onlyFavorites && !p.favorite) return false;
        if (customerSelect.selectedItem && p.customerId !== customerSelect.selectedItem.id) return false;
        return true;
    });

    switch (ordering) {
        case OrderingOption.Newest:
            filteredPlans = filteredPlans.sort((a, b) => (new Date(b.createdAt)).getTime() - (new Date(a.createdAt)).getTime());
            break;

        case OrderingOption.Oldest:
            filteredPlans = filteredPlans.sort((a, b) => (new Date(a.createdAt)).getTime() - (new Date(b.createdAt)).getTime());
            break;
        
        case OrderingOption.LastChanged:
            filteredPlans = filteredPlans.sort((a, b) => (new Date(b.updatedAt)).getTime() - (new Date(a.updatedAt)).getTime());
            break;
    }

    return {
        element,
        filteredPlans,
    };
};
