/** @format */

import { FormEvent, useContext, useEffect, useRef, useState } from "react";
import { IoBusinessOutline, IoLockClosed, IoLockClosedOutline, IoPerson } from "react-icons/io5";
import { UserService } from "../Api/User";
import "./LoginSignup.css";
import { ViewType } from "./ViewType";
import LoginImage from "../Assets/loginimage.jpg";
import { UserContext } from "../App";
import { UseLoading } from "../Shared/Loading/UseLoading";

export const LoginSignup = () => {
    const loggedInUser = useContext(UserContext);
    const [viewType, setViewType] = useState<ViewType>(ViewType.Login);
    const formRef = useRef<HTMLFormElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const confirmPwdRef = useRef<HTMLInputElement>(null);
    const deselectorRef = useRef<HTMLDivElement>(null);
    const useActionLoader = UseLoading();

    const submitFormData = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const items: any = e.target;

        switch (viewType) {
            case ViewType.Login:
                await loginUser(items);
                break;
            case ViewType.Signup:
                await signupUser(items);
                break;
            case ViewType.ForgotPwd:
                await forgotPasswordUser(items);
                break;
        }

        useActionLoader.stopLoading();

        setTimeout(() => {
            clearValidation();
        }, 2000);
    };

    const clearValidation = () => {
        emailRef.current?.setCustomValidity("");
        emailRef.current?.reportValidity();

        if (confirmPwdRef && confirmPwdRef.current) {
            confirmPwdRef.current?.setCustomValidity("");
            confirmPwdRef.current?.reportValidity();
        }
    };

    const loginUser = async (items: any) => {
        useActionLoader.startLoading();
        const user = await UserService.LoginAsync(items.email.value, items.password.value);
        if (user) {
            loggedInUser?.checkUserLogin();
        } else {
            deselectorRef.current?.focus();
            emailRef.current?.setCustomValidity("Brukernavn eller passord er feil");
            emailRef.current?.reportValidity();
        }
    };

    const validateEmail = (email: string): boolean => {
        email = String(email).toLowerCase();
        const regex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    };

    const signupUser = async (items: any) => {
        useActionLoader.startLoading();
        if (items.password.value !== items.passwordconfirm.value) {
            confirmPwdRef.current?.setCustomValidity("Passordene er ikke like");
            return confirmPwdRef.current?.reportValidity();
        }

        if (!validateEmail(items.email.value)) {
            emailRef.current?.setCustomValidity("Denne eposten er ikke gyldig");
            return emailRef.current?.reportValidity();
        }

        const user = await UserService.CreateAsync(items.name.value, items.email.value, items.password.value);

        if (!user) {
            emailRef.current?.setCustomValidity(
                "Mest sannsynlig finnes det allerede en bruker på denne e-posten, men kan være noe annet galt. Hør mer support@repset.no om du ikke klarer lage en bruker"
            );
            return emailRef.current?.reportValidity();
        }

        loggedInUser?.checkUserLogin();
    };

    const forgotPasswordUser = async (items: any) => {
        useActionLoader.startLoading();
        const email = items.email.value;

        if (!validateEmail(email)) {
            emailRef.current?.setCustomValidity("Denne eposten er ikke gyldig");
            return emailRef.current?.reportValidity();
        }

        await UserService.ForgotPasswordAsync(email);
        alert("Epost sendt hvis bruker eksisterer, sjekk innboks og spam/reklame mappe");
        setViewType(ViewType.Login);
    };

    useEffect(() => {
        if (formRef.current) {
            const firstElement: any = formRef.current.elements[0];
            firstElement.focus();
            formRef.current.reset();
        }
    }, [formRef, viewType]);

    let title = "Login";
    if (viewType === ViewType.Login) title = "Login";
    if (viewType === ViewType.Signup) title = "Lag ny konto";
    if (viewType === ViewType.ForgotPwd) title = "Glemt passord";

    return (
        <div id="LoginSignup">
            <div className="modal">
                <div ref={deselectorRef} />
                <div className="viewSection section">
                    <h1>{title}</h1>
                    <p className="welcomeText">
                        Repset.{" "}
                        <a
                            href="_"
                            onClick={(e) => {
                                e.preventDefault();
                                setViewType(ViewType.Login);
                            }}
                        >
                            Login
                        </a>{" "}
                        eller lag en{" "}
                        <a
                            href="_"
                            onClick={(e) => {
                                e.preventDefault();
                                setViewType(ViewType.Signup);
                            }}
                        >
                            ny konto
                        </a>
                    </p>

                    <form onSubmit={submitFormData} ref={formRef}>
                        <div className="inputGroup">
                            <input type="email" ref={emailRef} required placeholder="E-post" minLength={5} maxLength={254} name="email" />
                            <IoPerson />
                        </div>

                        {viewType === ViewType.Signup ? (
                            <>
                                <div className="inputGroup">
                                    <input type="text" required placeholder="Navn" name="name" minLength={1} maxLength={50} />
                                    <IoBusinessOutline />
                                </div>
                            </>
                        ) : null}

                        {viewType !== ViewType.ForgotPwd ? (
                            <div className="inputGroup">
                                <input type="password" required placeholder="Passord" minLength={4} name="password" />
                                <IoLockClosedOutline />
                            </div>
                        ) : null}

                        {viewType === ViewType.Signup ? (
                            <div className="inputGroup">
                                <input type="password" ref={confirmPwdRef} required minLength={4} placeholder="Bekreft Passord" name="passwordconfirm" />
                                <IoLockClosed />
                            </div>
                        ) : null}

                        <div className="btnGroup">
                            <div style={{ display: viewType === ViewType.ForgotPwd ? "block" : "none" }} />
                            <a
                                style={{ display: viewType === ViewType.ForgotPwd ? "none" : "block" }}
                                href="_"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setViewType(ViewType.ForgotPwd);
                                }}
                            >
                                Glemt passord?
                            </a>
                            {useActionLoader.loadingComponent}
                            <input type="submit" disabled={useActionLoader.isLoading} value={title} />
                        </div>
                    </form>
                </div>
                <div className="imageSection section">
                    <img src={LoginImage} alt="SkyView" />
                    <div className="overlay">
                        <img src="iconWithText.svg" alt="logo" />
                        <h2>Velkommen</h2>
                        <p style={{ fontWeight: 500 }}>Her oppretter du treningsprogrammer, spesielt tilpasset fysikalske behandlere, personlige trenere og treningssentere.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
