/** @format */

import React, { useEffect, useRef } from "react";

export interface IPlanVideoProps {
    src: string;
    visible: boolean;
    onLoaded: () => any;
}

export const PlanListVideo = (props: IPlanVideoProps) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.playbackRate = 1.5;
        }
    }, []);

    return (
        <video
            onLoadedData={() => props.onLoaded()}
            src={props.src}
            autoPlay
            muted
            loop
            ref={videoRef}
            controls={false}
            disableRemotePlayback={true}
            style={{
                border: "none",
                boxShadow: "none",
                display: props.visible ? "block" : "none",
                userSelect: "none",
            }}
        />
    );
};
