/** @format */

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./Home/Home";
import { AdminPanel } from "../../AdminPanel/AdminPanel";
import { Plans } from "./Plans/Plans";
import { createContext, useContext, useEffect, useState } from "react";
import { ICustomer, IPlan, UserType } from "../../Api/Interfaces";
import { PlanService } from "../../Api/Plan";
import { CustomerService } from "../../Api/Customer";
import { PlanEdit } from "../PlanEdit/PlanEdit";
import { Customers } from "./Customers/Customers";
import { Settings } from "./Settings/Settings";
import { UserContext } from "../../App";

export interface IVisiblePlan extends IPlan {
    customer?: ICustomer;
}

export interface IStateManContext {
    plans: IVisiblePlan[];
    customers: ICustomer[];
    changePlanFavorite: (planId: number) => void;
    updateAsync: () => Promise<void>;
}

export const StateManContext = createContext<IStateManContext | null>(null);

export const StateMan = () => {
    const user = useContext(UserContext)

    const [allPlans, setAllPlans] = useState<IVisiblePlan[]>([]);
    const [allCustomers, setAllCustomers] = useState<ICustomer[]>([]);

    const updateAsync = async () => {
        const loadedPlans = await PlanService.ListAsync();
        const loadedCustomers = await CustomerService.ListAsync();

        const plans: IVisiblePlan[] = loadedPlans.map((p) => ({
            ...p,
            customer: loadedCustomers.find((c) => c.id === p.customerId),
        }));

        setAllPlans(plans);
        setAllCustomers(loadedCustomers);
    };

    const changePlanFavorite = async (planId: number) => {
        const allPlanCopy = [ ...allPlans ];
        const plan = allPlanCopy.find((p) => p.id === planId);
        if (!plan) return;

        plan.favorite = !plan.favorite;
        setAllPlans(allPlanCopy);
        PlanService.UpdateFavoriteAsync(planId, plan.favorite);
    };

    useEffect(() => {
        updateAsync();
    }, []);

    return (
        <div>
            <StateManContext.Provider
                value={{
                    plans: allPlans,
                    customers: allCustomers,
                    changePlanFavorite,
                    updateAsync,
                }}
            >
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home />}>
                            <Route path="" element={<Plans />} />
                            <Route path="customers" element={<Customers key="customers" />} />
                            <Route path="customers/create" element={<Customers key="customers-create" />} />
                            <Route path="customers/edit/:customerId" element={<Customers key="customers-edit" />} />
                            <Route path="settings" element={<Settings />} />
                        </Route>

                        <Route path="/plans/edit/:planId" element={<PlanEdit key="plans-edit" />} />
                        <Route path="/plans/create/:customerId" element={<PlanEdit key="plans-create-customer" />} />
                        <Route path="/plans/create" element={<PlanEdit key="plans-create" />} />
                        <Route path="/admin" element={<AdminPanel closeAdminPanel={() => {}} />} />
                    </Routes>
                </BrowserRouter>
            </StateManContext.Provider>
        </div>
    );
};
