
import { IoArrowBack } from "react-icons/io5";
import { IView, useRouting } from "../Shared/UseRouting";
import "./AdminPanel.css"
import { ExerciseView } from "./Exercise/ExerciseView";
import { StatisticsView } from "./Statistics/StatisticsView";
import { TagView } from "./Tag/TagView";
import { UserView } from "./Users/UserView";
import { TagTypeView } from "./TagType/TagTypeView";
import { useNavigate } from "react-router-dom";

const views: IView[] = [
    {
        name: "Øvelser",
        component: <ExerciseView />
    },
    {
        name: "Tagger",
        component: <TagView /> 
    },
    {
        name: "Tagg-typer",
        component: <TagTypeView /> 
    },
    {
        name: "Statistikk",
        component: <StatisticsView />
    },
    {
        name: "Brukere",
        component: <UserView />
    }
]

interface IAdminPanelProps {
    closeAdminPanel: () => void;
}

export const AdminPanel = (props: IAdminPanelProps) => {
    const viewEngine = useRouting(views)
    const navigate = useNavigate();

    return (
        <div id="AdminPanel">
            <div className="section navigation">
                <p>RepSet Admin Panel</p>
                <button onClick={() => {
                    navigate("/")
                    props.closeAdminPanel();
                }}><IoArrowBack />
                    Gå tilbake til hovedside</button>
                {viewEngine.component}
            </div>

            <div className="section view">
                {viewEngine.currentView.component}
            </div>
        </div>
    )
}