

import { Connector } from "./Connector";
import { IBasicStatistics } from "./Interfaces";

export const StatisticsService = {
    async GetBasicDataAsync(): Promise<IBasicStatistics | null> {
        const data = await Connector.getAsync("statistics/basic")
        if (!data.ok) return null;
        return data.content;
    },
}