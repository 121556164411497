/** @format */

import { useEffect, useState } from "react";
import { IoMailOutline } from "react-icons/io5";
import { CustomerService } from "../../Api/Customer";
import { PlanService } from "../../Api/Plan";
import { PlanRenderService } from "../../Api/PlanRender";
import { UseLoading } from "../../Shared/Loading/UseLoading";
import "./PlanShare.css";
import { IPlan } from "../../Api/Interfaces";

export interface IPlanShareProps {
    plan: IPlan;
    modal?: any;
}

export const PlanShare = (props: IPlanShareProps) => {
    const [email, setEmail] = useState<string>();
    const [description, setDescription] = useState(props.plan.description);
    const [url, setUrl] = useState<string>("");
    const useActionLoader = UseLoading();

    const sharePlanAsync = async () => {
        if (!email) return;

        const success = await PlanRenderService.ShareWithEmailAsync(props.plan.publicId, email, description);
        if (success) {
            alert("Epost sendt");
            if (props.modal.close) props.modal.close();
        } else {
            alert("FEIL! Klarte ikke sende epost, sjekk epost adressen og prøv igjen");
        }
    };

    const saveDescriptionAsync = async () => {
        if (props.plan.description === description) return;
        await PlanService.UpdateDescriptionAsync(props.plan.id, description);
    }

    const loadInitialEmailAsync = async () => {
        if (!props.plan.customerId) return;
        const customer = await CustomerService.GetAsync(props.plan.customerId);
        setEmail(customer?.email);
    };

    const loadContentPreview = () => {
        const previewUrl = PlanRenderService.GenerateEmailPreviewUrl(props.plan.publicId);
        setUrl(previewUrl);
    };

    useEffect(() => {
        loadContentPreview();
        loadInitialEmailAsync();
    }, []);

    return (
        <div className="PlanShare">
            <form
                onSubmit={async (e) => {
                    e.preventDefault();
                    useActionLoader.startLoading();
                    await saveDescriptionAsync();
                    await sharePlanAsync();
                    useActionLoader.stopLoading();
                }}
            >
                <div className="row">
                    <div className="inputGroup">
                        <input type="email" placeholder="Epost" value={email} onChange={(e) => setEmail(e.target.value)} required autoFocus />
                        {useActionLoader.loadingComponent}
                        <IoMailOutline />
                    </div>

                    <input type="submit" value="Del på epost" disabled={useActionLoader.isLoading} className="Button Primary" />
                </div>

                <textarea  maxLength={180} value={description} onChange={e => setDescription(e.target.value)} placeholder="Beskrivelse" />
            </form>

            <iframe src={url} />
        </div>
    );
};
