/** @format */

import { CSSProperties, useEffect, useState } from "react";
import Select from "react-select";
import { TagService } from "../Api/Tag";
import { ReactSelectable } from "./ISelectable";
import "./UseMultiTagSelect.css";

export interface IGroupedOption {
    label: string;
    options: ReactSelectable[];
}

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles: CSSProperties = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = (data: IGroupedOption) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

export const UseMultiTagSelect = (borderLess?: boolean) => {
    const [items, setItems] = useState<IGroupedOption[]>([]);
    const [selectedItems, setSelectedItems] = useState<ReactSelectable[]>([]);

    const reloadAsync = async () => {
        const data = await TagService.ListAsync();
        //data.sort((a, b) => a.name.localeCompare(b.name));

        const groupedData: IGroupedOption[] = [];

        // Default group
        groupedData.push({
            label: "Tagger",
            options: [],
        });
        const defaultGroup = groupedData[0];

        for (const tag of data) {
            if (!tag.type) {
                defaultGroup.options.push({
                    label: tag.name,
                    value: tag.id.toString(),
                });

                continue;
            }

            let group = groupedData.find((g) => g.label === tag.type?.name);
            if (!group) {
                group = { label: tag.type.name, options: [] };
                groupedData.push(group);
            }

            group.options.push({
                label: tag.name,
                value: tag.id.toString(),
            });
        }

        setItems(groupedData);
        setSelectedItems([]);
    };

    useEffect(() => {
        reloadAsync();
    }, []);

    const handleChangeAsync = (newValue: any) => {
        setSelectedItems(newValue);
    };

    const component = (
        <div className="UseMultiTagSelect" style={{ zIndex: 5 }}>
            <Select
                formatGroupLabel={formatGroupLabel}
                styles={{
                    container: (provided) => ({ ...provided, width: "100%", textAlign: "left" }),
                    control: borderLess
                        ? (provided) => ({
                              ...provided,
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              border: 0,
                              borderLeft: "1px solid gray",
                          })
                        : (provided) => ({ ...provided }),
                }}
                isMulti
                isClearable={true}
                placeholder={`Filtere på tagger`}
                noOptionsMessage={() => `Ingen tagger funnet`}
                value={selectedItems}
                options={items}
                onChange={handleChangeAsync}
                isLoading={false}
            />
        </div>
    );

    return {
        reloadAsync,
        items,
        selectedItems,
        component,
    };
};
