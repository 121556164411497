/** @format */

import { useContext } from "react";
import styles from "./Customers.module.css";
import { StateManContext } from "../StateMan";
import { useCustomerFilter } from "./Filters/useCustomerFilter";
import { CustomerList } from "./CustomerList/CustomerList";
import { Startup } from "../../Startup/Startup";
import { CreateEditCustomerModal } from "./CreateEditCustomerModal/CreateEditCustomerModal";
import { useLocation } from "react-router-dom";

export const Customers = () => {
    const stateMan = useContext(StateManContext);
    const customerFilter = useCustomerFilter(stateMan?.customers ?? []);
    const location = useLocation();

    return (
        <div className={styles.customers}>
            <Startup />

            {location.pathname !== "/customers" && (
                <CreateEditCustomerModal />
            )}

            {customerFilter.element}

            <CustomerList customers={customerFilter.filteredCustomers} />
        </div>
    );
};
