/** @format */

import { IMenuItem } from "./IMenuItem";

import { RiListCheck2 } from "react-icons/ri";
import { IoPersonOutline, IoSettingsOutline } from "react-icons/io5";

export const MenuItems: IMenuItem[] = [
    {
        icon: <RiListCheck2 />,
        text: "Planer",
        link: "/",
    },
    {
        icon: <IoPersonOutline />,
        text: "Kunder",
        link: "/customers",
    },
    {
        icon: <IoSettingsOutline />,
        text: "Innstillinger",
        link: "/settings",
    },
];
