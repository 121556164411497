/** @format */

import { Clock, UserRound } from "lucide-react";
import styles from "../../SharedStyles/ListItem.module.css";
import { StateManContext } from "../../StateMan";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ICustomer } from "../../../../Api/Interfaces";

export interface ICustomerListProps {
    customers: ICustomer[];
}

export const CustomerList = (props: ICustomerListProps) => {
    const navigate = useNavigate();

    if (props.customers.length === 0) {
        return (
            <div className={styles.noPlansContainer}>
                <p>Ingen kunder matcher filtreringen. Opprett en kunde med |+ Ny kunde| knappen oppe til høyre</p>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <ul>
                {props.customers.map((customer, i) => (
                    <li style={{ width: "100%" }} key={i}>
                        <button className={`${styles.listBtn}`} style={{ paddingTop: 20, paddingBottom: 20 }} onClick={() => {
                            navigate(`/customers/edit/${customer.id}`)
                        }}>
                            <div className={styles.leftSide}>
                                <div className={styles.imageContainer}>
                                    <UserRound size={32} />

                                    <div title="Antall planer koblet på kunden" className={styles.countText}>{customer.planCount}</div>
                                </div>

                                <div className={styles.primaryText}>
                                    <h2>{customer.name}</h2>
                                    <p>{customer.email?.length > 0 ? customer.email : "Ingen e-post"}</p>
                                </div>
                            </div>

                            <div className={styles.rightSide}>
                                <div className={styles.secondaryText}>
                                    <p title="Sist endret">
                                        {new Date(customer.updatedAt).toLocaleDateString()}
                                        <Clock />
                                    </p>
                                </div>
                            </div>
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};
