
import { Connector } from "./Connector";
import { IExercise } from "./Interfaces";

export interface IFileCreate {
    fileId: string;
    name: string;
    ordering: number;
    role: number;
}

export const ExerciseService = {
    async ListAsync(): Promise<IExercise[] | null> {
        const data = await Connector.getAsync("exercise")
        if (!data.ok) return null;
        return data.content;
    },

    async GetAsync(id: number): Promise<IExercise | null> {
        const data = await Connector.getAsync(`exercise/${id}`)
        if (!data.ok) return null;
        return data.content;
    },

    async CountAsync(): Promise<Map<number, number>> {
        const data = await Connector.getAsync("exercise/count");
        const map = new Map<number, number>();
        if (!data.ok) return map;

        data.content.forEach((item: any) => {
            map.set(item.exerciseId, item.count);
        });

        return map;
    },

    async CreateAsync(name: string, description: string, tagIds: number[], files: IFileCreate[], searchWords: string[]): Promise<number | null> {
        const data = await Connector.postAsync("exercise", { name, description, tagIds, files, searchWords, });
        if (!data.ok) return null;
        return data.content.id;
    },

    async EditAsync(name: string, description: string, tagIds: number[], files: IFileCreate[], searchWords: string[], id: number): Promise<boolean> {
        const data = await Connector.putAsync(`exercise/${id}`, { name, description, tagIds, files, searchWords, });
        return data.ok;
    },

    async DeleteAsync(id: number): Promise<boolean> {
        const data = await Connector.deleteAsync(`exercise/${id}`);
        return data.ok;
    },

    async SetFavoriteStatusAsync(id: number, isFavorite: boolean): Promise<void> {
        await Connector.getAsync(`exercise/${id}/favorite/${+isFavorite}`)
    },
}