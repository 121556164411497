/** @format */

import Fuse from "fuse.js";
import { ISearchItem } from "./Interfaces";

export interface IGenericSearchItem<T> {
    items: T[];
    fieldsToSearch: string[];
}

export class GenericSearchEngine<T> {
    private readonly fuse: Fuse<T>;
    private allItems: T[] = [];

    constructor(searchData: IGenericSearchItem<T>) {
        const options = {
            distance: 100,
            useExtendedSearch: true,
            keys: searchData.fieldsToSearch,
        };

        this.allItems = searchData.items;
        this.fuse = new Fuse(searchData.items, options);
    }

    public Search(text: string): T[] {
        if (text.trim() === "") {
            return this.allItems;
        }

        const fuseResult: any[] = this.fuse.search(text);

        for (var i = 0; i < fuseResult.length; i++) {
            fuseResult[i] = fuseResult[i].item;
        }

        return fuseResult;
    }
}
