/** @format */

import { IoPricetagSharp, IoSaveOutline, IoTrashOutline } from "react-icons/io5";
import "./SingleTag.css";
import ReactSelect from "react-select";
import { ITag, ITagType } from "../../../Api/Interfaces";
import { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { ReactSelectable } from "../../../Shared/ISelectable";
import { TagService } from "../../../Api/Tag";
import { IoIosRemoveCircleOutline } from "react-icons/io";

export interface ISingleTagProps {
    tag: ITag;
    tagTypes: ITagType[];
    isSelected: boolean;
    changed: () => any;
    closeItem: () => any;
}

export const SingleTag = (props: ISingleTagProps) => {
    const [name, setName] = useState(props.tag.name);
    const [selectedType, setSelectedType] = useState(props.tag.type);

    useEffect(() => {
        setName(props.tag.name);
        setSelectedType(props.tag.type);
    }, [props.isSelected]);

    const saveTag = async () => {
        await TagService.UpdateAsync(props.tag.id, name, selectedType?.id ?? null)
        props.changed()
    }

    const deleteTag = async () => {
        const shouldDelete = window.confirm("Er du sikker på at du vil slette taggen?");
        if (!shouldDelete) return;

        await TagService.DeleteAsync(props.tag.id);

        props.changed();
    }

    const removeTagType = async () => {
        setSelectedType(null);
    }

    return (
        <div className="SingleTag customerItem">
            {props.isSelected ? (
                <div className="editable">
                    <div className="inputGroup" style={{ width: "100%" }}>
                        <input autoFocus required name="name" placeholder="Navn" value={name} onChange={(e) => setName(e.target.value)} />
                        <IoPricetagSharp />
                    </div>

                    <div className="selectWrapper">
                        <CreatableSelect
                            isClearable
                            options={props.tagTypes.map((t) => ({
                                label: t.name,
                                value: t.id.toString(),
                            }))}
                            onChange={(newValue: any) => {
                                const item = props.tagTypes.find((c) => c.id === parseInt(newValue.value));
                                setSelectedType(item || null);
                            }}
                            value={selectedType ? {

                                label: selectedType?.name,
                                value: selectedType?.id.toString(),
                            } : null}
                        />
                    </div>
                </div>
            ) : (
                <div>
                    <h2>{props.tag.name}</h2>
                    {props.tag.type?.name && <h3>{props.tag.type.name}</h3>}
                </div>
            )}

            <div className="selected btnGroup" onClick={(e) => e.stopPropagation()} style={{ display: props.isSelected ? "flex" : "none", marginTop: 20 }}>
                <button onClick={removeTagType}>
                    <IoIosRemoveCircleOutline />
                    Fjern tagg-type
                </button>

                <button onClick={deleteTag}>
                    <IoTrashOutline style={{ color: "red" }} />
                    Slett
                </button>

                <button onClick={saveTag}>
                    <IoSaveOutline />
                    Large
                </button>
            </div>
        </div>
    );
};
