/** @format */

import { IoClose } from "react-icons/io5";
import "./UseModal.css";
import { useEffect } from "react";

export const ActiveModal = (props: any) => {
    useEffect(() => {
        document.addEventListener("keyup", handleKeyPress);
        return () => {
            document.removeEventListener("keyup", handleKeyPress);
        };
    }, []);

    const handleKeyPress = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
            props.onClose();
        }
    };

    return (
        <div className="UseModal" style={{ display: props.isOpen ? undefined : "none" }}>
            <div className="innerModal">
                <header className="modalHeader">
                    <div>
                        <p>{props.title}</p>
                    </div>
                    <button onClick={props.onClose}>
                        <IoClose />
                    </button>
                </header>

                <div className="modalWrapper">{props.children}</div>
            </div>
        </div>
    );
};
