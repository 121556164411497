/** @format */

import styles from "./Filters.module.css";
import multiOption from "../../SharedStyles/MultiOption.module.css";
import { useState } from "react";
import { IoSearch } from "react-icons/io5";
import { GenericSearchEngine } from "../../../../Shared/Search/GenericSearchEngine";
import { ICustomer } from "../../../../Api/Interfaces";
import { FaUserPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

enum OrderingOption {
    Newest,
    Oldest,
    LastChanged,
}

export const useCustomerFilter = (allCustomers: ICustomer[]) => {
    const [searchText, setSearchText] = useState("");
    const [ordering, setOrdering] = useState<OrderingOption>(OrderingOption.LastChanged);
    const navigate = useNavigate()

    const element = (
        <div className={styles.container}>
            <div className={styles.filtering}>
                <div className={`UseSearch ${styles.searchBox}`}>
                    <IoSearch />
                    <input placeholder="Søk" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                </div>

                <button className={`${styles.createBtn}`} onClick={() => navigate("/customers/create")}>
                    <FaUserPlus />
                    Ny kunde
                </button>
            </div>

            <div className={multiOption.container}>
                <button data-selected={ordering === OrderingOption.LastChanged} onClick={() => setOrdering(OrderingOption.LastChanged)}>
                    Sist endret
                </button>

                <button data-selected={ordering === OrderingOption.Newest} onClick={() => setOrdering(OrderingOption.Newest)}>
                    Nyeste
                </button>

                <button data-selected={ordering === OrderingOption.Oldest} onClick={() => setOrdering(OrderingOption.Oldest)}>
                    Eldste
                </button>
            </div>
        </div>
    );

    const searchEngine = new GenericSearchEngine<ICustomer>({ items: allCustomers, fieldsToSearch: ["name", "email"] });
    let filteredCustomers = searchEngine.Search(searchText);

    switch (ordering) {
        case OrderingOption.Newest:
            filteredCustomers = filteredCustomers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            break;

        case OrderingOption.Oldest:
            filteredCustomers = filteredCustomers.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
            break;

        case OrderingOption.LastChanged:
            filteredCustomers = filteredCustomers.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
            break;
    }

    return {
        element,
        filteredCustomers,
    };
};
