/** @format */

import { useEffect, useState } from "react";
import Select from "react-select";
import { PlanService } from "../../Api/Plan";
import { PlanRenderService } from "../../Api/PlanRender";
import { ReactSelectable } from "../../Shared/ISelectable";
import { UseLoading } from "../../Shared/Loading/UseLoading";
import "./PlanPrint.css";
import { IPlan } from "../../Api/Interfaces";

export interface IPlanPrintProps {
    plan: IPlan;
    modal?: any;
}

export const PlanPrint = (props: IPlanPrintProps) => {
    const useActionLoader = UseLoading();

    const sizingItems: ReactSelectable[] = [
        { label: "1 øvelse per A4 side", value: "1" },
        { label: "2 øvelser per A4 side", value: "2" },
        { label: "3 øvelser per A4 side", value: "3" },
        { label: "4 øvelser per A4 side", value: "4" },
        { label: "5 øvelser per A4 side", value: "5" },
        { label: "6 øvelser per A4 side", value: "6" },
    ];

    const [selectedSize, setSelectedSize] = useState<ReactSelectable>(sizingItems[3]);
    const [description, setDescription] = useState(props.plan.description);
    const [url, setUrl] = useState<string>("");

    const saveDescriptionAsync = async () => {
        if (props.plan.description === description) return;
        await PlanService.UpdateDescriptionAsync(props.plan.id, description);
    }

    const printPlanAsync = async () => {
        await saveDescriptionAsync();
        try {
            const newWindow = window.open("about:blank", "_blank");
            if (newWindow) {
                newWindow.location.href = url;
                newWindow.focus();
            }
        } catch (error) {}

        if (props.modal.close) props.modal.close();
    };

    const renderPlan = async (size: number) => {
        const pdfUrl = PlanRenderService.GeneratePdfUrl(props.plan.publicId, size);
        setUrl(pdfUrl);
    };

    useEffect(() => {
        renderPlan(parseInt(selectedSize.value));
    }, []);

    return (
        <div className="PlanPrint">
            <form
                onSubmit={async (e) => {
                    e.preventDefault();
                    useActionLoader.startLoading();
                    await printPlanAsync();
                    useActionLoader.stopLoading();
                }}
            >
                {useActionLoader.loadingComponent}

                <div className="row">
                    <div className="select">
                        <Select
                            autoFocus
                            onChange={(newValue: any) => {
                                setSelectedSize(newValue);
                                renderPlan(parseInt(newValue.value));
                            }}
                            isClearable={false}
                            value={selectedSize}
                            options={sizingItems}
                        />
                    </div>

                    <input type="submit" disabled={useActionLoader.isLoading} value="Print Pdf" className="Button Primary" />
                </div>


                <textarea maxLength={180} value={description} onChange={e => setDescription(e.target.value)} placeholder="Beskrivelse" />
            </form>

            <iframe src={url + "#toolbar=0&navpanes=0"} />
        </div>
    );
};
