/** @format */

import { useEffect } from "react";
import FocusTrap from "focus-trap-react";
import styles from "../../MainPage/Customers/CreateEditCustomerModal/CreateEditCustomerModal.module.css"
import { CustomerEdit } from "../../MainPage/Customers/CreateEditCustomerModal/CustomerEdit/CustomerEdit";

interface ICreateCustomerModalProps {
    exitModal: () => any;
}

export const CreateCustomerModal = (props: ICreateCustomerModalProps) => {
    const onKeyUp = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
            props.exitModal();
        }
    };

    useEffect(() => {
        document.addEventListener("keyup", onKeyUp);
        return () => {
            document.removeEventListener("keyup", onKeyUp);
        };
    }, []);

    return (
        <FocusTrap>
            <div className={styles.wrapper}>
                <div className={styles.modal}>
                    <div className={`${styles.section} ${styles.editCustomerSection}`}>
                        <CustomerEdit exitModal={props.exitModal} />
                    </div>
                </div>

                <div />
            </div>
        </FocusTrap>
    );
};
