/** @format */

import { useEffect, useState, createContext } from "react";
import { IUser, UserType } from "./Api/Interfaces";
import { UserService } from "./Api/User";
import { LoginSignup } from "./LoginSignup/LoginSignup";
import { Loading } from "./Shared/Loading/Loading";
import { StateMan } from "./Main/MainPage/StateMan";

enum LogInStatus {
    NotChecked = 0,
    LoggedIn = 1,
    LoggedOut = 2,
}

export interface IUserContext extends IUser {
    checkUserLogin: () => void;
}

export const UserContext = createContext<IUserContext | null>(null);

export const App = () => {
    const [status, setStatus] = useState<LogInStatus>(LogInStatus.NotChecked);
    const [user, setUser] = useState<IUserContext | null>(null);
    const loginComponent = <LoginSignup />;

    useEffect(() => {
        checkUserLogin();
    }, []);

    const checkUserLogin = async () => {
        const user = await UserService.GetAsync();
        if (user) {
            const userExtended: IUserContext = { ...user, checkUserLogin };
            setUser(userExtended);
        } else {
            setUser({
                checkUserLogin,
                created: new Date(),
                email: "",
                id: -1,
                isEmailValidated: false,
                name: "",
                icon: "",
                type: UserType.normal,
                isPaid: false,
                isPayingCustomer: false,
            });
        }

        if (user) {
            setStatus(LogInStatus.LoggedIn);
        } else {
            setStatus(LogInStatus.LoggedOut);
        }
    };

    if (status === LogInStatus.NotChecked) {
        return <Loading />;
    } else {
        return (
            <UserContext.Provider value={user}>
                {status === LogInStatus.LoggedIn ? (
                    <>
                        <StateMan />

                        {(user?.type === UserType.test || user?.type === UserType.admin) && (
                            <div className="typeBadge">
                                <p>{user?.type === UserType.admin ? "Admin" : "Test"}</p>
                            </div>
                        )}
                    </>
                ) : (
                    loginComponent
                )}
            </UserContext.Provider>
        );
    }
};
