/** @format */

import { GiWeight } from "react-icons/gi";
import { IoMdTimer } from "react-icons/io";
import { IoRepeatOutline } from "react-icons/io5";
import { IPlanExercise } from "../../Api/Interfaces";
import "./PlanExerciseEdit.css";
import { FileRole } from "../../Api/FileRoleEnum";
import { PlanListVideo } from "../PlanEdit/PlanView/PlanListVideo";
import { IExerciseWithImage } from "../PlanEdit/PlanEdit";

export interface IPlanExerciseEditProps {
    planItemId: number;
    planExercises: IPlanExercise[];
    exercise: IExerciseWithImage | null;
    setPlanExercises: (data: any) => any;
}

export const PlanExerciseEdit = (props: IPlanExerciseEditProps) => {
    const setData = (type: string, value: string) => {
        const setter = props.planExercises.map((pe) => {
            if (pe.id !== props.planItemId) return pe;

            const newPe: any = { ...pe };
            newPe[type] = value;
            return newPe;
        });

        props.setPlanExercises(setter);
    };

    const planExercise = props.planExercises.find((e) => e.id === props.planItemId);

    const videos = props.exercise?.files.filter(f => f.role == FileRole.video) ?? [];
    const videoUrl = videos?.length > 0 ? videos[0].publicUrl : "";

    return (
        <div className="PlanExerciseEdit">
            <hr />
            <div className="miniInputs">
                <div className="inputGroup">
                    <input
                        type="text"
                        placeholder="Sets"
                        maxLength={48}
                        value={planExercise?.sets}
                        onChange={(e) => {
                            setData("sets", e.target.value);
                        }}
                    />
                    <IoMdTimer />
                </div>

                <div className="inputGroup">
                    <input
                        type="text"
                        placeholder="Reps"
                        maxLength={48}
                        value={planExercise?.reps}
                        onChange={(e) => {
                            setData("reps", e.target.value);
                        }}
                    />
                    <IoRepeatOutline />
                </div>

                <div className="inputGroup">
                    <input
                        type="text"
                        placeholder="Vekt"
                        maxLength={48}
                        value={planExercise?.weight}
                        onChange={(e) => {
                            setData("weight", e.target.value);
                        }}
                    />
                    <GiWeight />
                </div>
            </div>

            <div className="mainInputs">
                <div className="commentBox">
                    <textarea
                        placeholder="Kommentar"
                        maxLength={1000}
                        value={planExercise?.comment}
                        onChange={(e) => {
                            setData("comment", e.target.value);
                        }}
                    />

                    <button
                        onClick={(e) => {
                            if (props.exercise?.description) {
                                const text = props.exercise?.description ?? "tekst ikke funnet"
                                setData("comment", text);
                            }
                        }}
                        className="Button Primary NoShadow Small"
                    >
                        Tilbakestill til originaltekst
                    </button>
                </div>
            </div>

            <div className="files">
                {props.exercise?.files.filter(f => f.role == FileRole.image).map((fileInfo, i) => <img src={fileInfo.publicUrl} alt="img" key={i} />)}
                <PlanListVideo onLoaded={() => {}} src={videoUrl} visible={videoUrl !== ""} />
            </div>

            <div></div>
        </div>
    );
};
