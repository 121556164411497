/** @format */

import { CircleAlert, DollarSign, KeyRound } from "lucide-react";
import { PrettyDetails } from "../../../Shared/PrettyDetails/PrettyDetails";
import styles from "./Settings.module.css";
import { GeneralInfo } from "./Sections/GeneralInfo";
import { Password } from "./Sections/Password";
import { Payment } from "./Sections/Payment";
import { Dangerzone } from "./Sections/Dangerzone";

export const Settings = () => {
    return (
        <div className={styles.container}>
            <GeneralInfo />
            <Password />
            <Payment />
            <Dangerzone />
        </div>
    );
};
