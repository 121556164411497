/** @format */

import React, { useContext, useEffect, useState } from "react";
import { UserType } from "../../Api/Interfaces";
import { UserService } from "../../Api/User";
import { UserContext } from "../../App";
import { RequiredModal } from "../../Shared/Modal/RequiredModal";
import { Payment } from "./Payment/Payment";
import { PaymentValidation } from "./PaymentValidation/PaymentValidation";
import "./Startup.css";
import { ValidateEmail } from "./ValidateEmail/ValidateEmail";
import { CustomPayment } from "./Payment/CustomPayment";
import { useNavigate } from "react-router-dom";

export interface IStartupProps {
    showSettingsPanel?: () => void;
}

export const Startup = (props: IStartupProps) => {
    const user = useContext(UserContext);
    const [returnComponent, setReturnComponent] = useState<React.ReactElement | null>(null);
    const navigate = useNavigate();

    const showSettingsPanel = () => {
        navigate("/settings")
    }

    const checkStatusAsync = async () => {
        if (!user) {
            return setReturnComponent(null);
        }

        if (user.type === UserType.test || user.type === UserType.admin) {
            return setReturnComponent(null);
        }

        if (!user.isEmailValidated) {
            return setReturnComponent(
                <ValidateEmail
                    clickValidation={() => {
                        setReturnComponent(<Payment showSettingsPanel={showSettingsPanel} />);
                    }}
                    showSettingsPanel={showSettingsPanel}
                />
            );
        }

        if (!user.isPayingCustomer) {
            const customPrice = await UserService.PaymentGetCustomPriceAsync();
            if (customPrice) {
                return setReturnComponent(<CustomPayment showSettingsPanel={showSettingsPanel} price={customPrice} />)
            } 

            return setReturnComponent(<Payment showSettingsPanel={showSettingsPanel} />);
        }

        const status = await UserService.PaymentStatusCheckAsync();
        if (status.needToPay) {
            const link = await UserService.PaymentPortalAsync();
            return setReturnComponent(<PaymentValidation portalUrl={link} message={status.humanMessage} />);
        }

        UserService.PaymentRegisterActive();
    };

    useEffect(() => {
        checkStatusAsync();
    }, []);

    if (returnComponent === null) {
        return null;
    }

    return (
        <div className="Startup">
            <RequiredModal isOpen={returnComponent !== null} style={{ width: "initial", height: "initial", maxWidth: 800 }}>
                <div className="modalWrapper">{returnComponent}</div>
            </RequiredModal>
        </div>
    );
};
