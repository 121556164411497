/** @format */

import { useContext, useRef, useState } from "react";
import { IoLockClosedOutline } from "react-icons/io5";
import { UserService } from "../../../Api/User";
import { UserContext } from "../../../App";
import { UseLoading } from "../../../Shared/Loading/UseLoading";
import "./ValidateEmail.css";

export interface IValidateEmailProps {
    showSettingsPanel: () => void;
    clickValidation: () => void;
}

export const ValidateEmail = (props: IValidateEmailProps) => {
    const user = useContext(UserContext);
    const [inputCode, setInputCode] = useState<string>("");
    const useActionLoader = UseLoading();
    const inputRef = useRef<HTMLInputElement>(null);

    if (!user) return <></>;
    if (user && user.isEmailValidated) return <></>;

    const reSendEmailValidationAsync = async () => {
        useActionLoader.startLoading();
        const success = await UserService.ResendValidateEmailAsync();
        useActionLoader.stopLoading();
        if (!success) {
            alert(
                "Klarte ikke sende bekreftelses-epost på nytt, hvis du har skrevet inn eposten feil, kan du lage en ny konto med en annen epost, eller kontakt repset"
            );
        }
    };

    const confirmEmailAsync = async () => {
        if (!inputCode) return;
        if (inputCode.length !== 4) {
            setTimeout(() => {
                inputRef.current?.setCustomValidity("");
                inputRef.current?.reportValidity();
            }, 1500);

            inputRef.current?.setCustomValidity("Pin koden må være 4 siffer");
            return inputRef.current?.reportValidity();
        } 

        useActionLoader.startLoading();

        const code = parseInt(inputCode);
        const success = await UserService.ValidateEmailAsync(code);

        useActionLoader.stopLoading();
        if (!success) {
            setTimeout(() => {
                inputRef.current?.setCustomValidity("");
                inputRef.current?.reportValidity();
            }, 500);

            inputRef.current?.setCustomValidity("Feil kode");
            return inputRef.current?.reportValidity();
        }

        user.checkUserLogin();
        props.clickValidation(); 
    };

    const invalidInputChars = ["-", "+", "e", ".", ","];

    return (
        <div className="ValidateEmail">
            <h1>Bekreft epost</h1>
            <p>
                Skriv inn koden du fikk på epost her? Har du ikke fått epost, så sjekk søppelpost/reklame mappene.{" "}
                <a
                    onClick={(e) => {
                        e.preventDefault();
                        reSendEmailValidationAsync();
                    }}
                    href=""
                >
                    Send bekreftelses-kode på nytt
                </a>{" "}
                ,{" "}
                <a
                    onClick={async (e) => {
                        e.preventDefault();
                        props.showSettingsPanel();
                    }}
                    href=""
                >
                    Endre epost
                </a>{" "}
                eller{" "}
                <a
                    onClick={async (e) => {
                        e.preventDefault();
                        await UserService.LogoutAsync();
                        user.checkUserLogin();
                    }}
                    href=""
                >
                    Logg ut
                </a>
            </p>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    confirmEmailAsync();
                }}
            >
                <div className="inputGroup">
                    <input
                        value={inputCode}
                        ref={inputRef}
                        onChange={(e) => setInputCode(e.target.value)}
                        className="codeInput"
                        type="number"
                        min={0}
                        step={1}
                        pattern="\d*"
                        maxLength={4}
                        placeholder="Kode"
                        onInput={(e: any) => {
                            if (e.target.value.length > e.target.maxLength) {
                                e.target.value = e.target.value.slice(0, e.target.maxLength);
                            }
                        }}
                        onKeyDown={(e) => {
                            if (invalidInputChars.includes(e.key)) {
                                e.preventDefault();
                            }
                        }}
                    />
                    <IoLockClosedOutline />
                    {useActionLoader.loadingComponent}
                </div>

                <input
                    type="submit"
                    disabled={useActionLoader.isLoading}
                    value="Bekreft epost"
                    className="Button Primary btnInput"
                />
            </form>
        </div>
    );
};
