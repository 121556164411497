/** @format */

import { useEffect, useRef, useState } from "react";
import "./UserView.css";
import { IAdminUser } from "../../Api/Interfaces";
import { UserService } from "../../Api/User";
import { ExpandList } from "../../Shared/ListFramework/ExpandList";
import { SingleUser } from "./SingleUser";
import { useScreenSize } from "../../Shared/UseScreenSize";
import { GenericSearchEngine } from "../../Shared/Search/GenericSearchEngine";
import { IoSearch } from "react-icons/io5";

export const UserView = () => {
    const [users, setUsers] = useState<IAdminUser[]>([]);
    const screenSize = useScreenSize();
    const listRef = useRef<HTMLDivElement>(document.createElement("div"));
    const [searchQuery, setSearchQuery] = useState("");

    const loadUsers = async () => {
        const data = await UserService.AdminListAllUsersAsync();
        setUsers(data);
    };

    useEffect(() => {
        loadUsers();
    }, []);

    const listHeight = screenSize.height - (listRef?.current?.offsetTop ?? 0) - 10;

    const searchEngine = new GenericSearchEngine<IAdminUser>({ items: users, fieldsToSearch: ["name"] });
    const searchItems = searchEngine.Search(searchQuery);

    return (
        <div className="AdminListSimpleView UserView">
            <div className="info">
                <h1>Brukere</h1>
                <p>
                    <b>Vær forsiktig med å endre</b> da dette vil føre til endringer på reelle brukere
                </p>
            </div>

            <div className="searchBox">
                <div className={`UseSearch`}>
                    <IoSearch />
                    <input
                        placeholder="Søk"
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                        }}
                    />
                </div>
            </div>

            <ExpandList
                customRef={listRef}
                items={searchItems}
                customStyles={{ maxHeight: `${listHeight}px`, overflowY: "auto", height: "initial" }}
                componentRendering={(currentUser, isSelected) => {
                    return <SingleUser reloadUsers={loadUsers} user={currentUser} isSelected={isSelected} />;
                }}
            />
        </div>
    );
};
