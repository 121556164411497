/** @format */

import { useEffect, useState } from "react";
import { IExercise } from "../../Api/Interfaces";
import { ExerciseService } from "../../Api/Exercise";
import "./ExerciseView.css";
import { IHandler, IItem } from "../../Shared/List/Interfaces";
import { CrudList } from "../../Shared/CrudList/CrudList";
import { GiWeightLiftingUp } from "react-icons/gi";
import { useModal } from "../../Shared/Modal/UseModal";
import { CreateExercise } from "./CreateExercise";

export const ExerciseView = () => {
    const [exercises, setExercises] = useState<IExercise[]>([]);
    const [counts, setCounts] = useState<Map<number, number>>(new Map<number, number>());

    const createModal = useModal("Øvelse", false, () => {
        updateExercises();
    });

    const updateExercises = async () => {
        const data = await ExerciseService.ListAsync();
        if (data) setExercises(data);
    };

    const countExercises = async () => {
        const data = await ExerciseService.CountAsync();
        setCounts(data);
    };

    useEffect(() => {
        countExercises();
        updateExercises();
    }, []);

    const listCompatiableItems: IItem[] = [];
    exercises.forEach((dt) => {
        const item: IItem = {
            id: dt.id,
            name: dt.name,
            extraTextBottom: `Brukt: ${counts.get(dt.id) ?? "0"} ganger`
        };

        listCompatiableItems.push(item);
    });

    const customHandlers: IHandler[] = [
        {
            name: "Rediger",
            callback: (item) => {
                createModal.open(<CreateExercise id={item.id} />);
            },
            id: 1,
        },
    ];

    return (
        <div className="AdminListSimpleView">
            {createModal.component}
            <div className="info">
                <h1>Øvelser</h1>
                <p>
                    <b>Vær forsiktig med og endre</b>, da dette vil føre til endring i produksjon{" "}
                </p>
            </div>
            <CrudList
                crudComponent={(item) => (
                    <div className="inputGroup" style={{ width: "100%" }}>
                        <input
                            autoFocus
                            required
                            minLength={1}
                            maxLength={50}
                            name="name"
                            placeholder="Navn"
                            defaultValue={item?.name}
                        />
                        <GiWeightLiftingUp />
                    </div>
                )}
                customCreateAction={() => {
                    createModal.open(<CreateExercise id={-1} />);
                }}
                handlers={customHandlers}
                onDelete={async (id) => {
                    const confirmation = window.confirm(
                        "Sikker på at du vil slette øvelsen? Den vil slettes fra alle brukere som har øvelsen i sin treningsplan"
                    );
                    if (confirmation) {
                        await ExerciseService.DeleteAsync(id);
                        updateExercises();
                    }
                }}
                items={listCompatiableItems}
            />
        </div>
    );
};
