/** @format */

import { DollarSign } from "lucide-react";
import { PrettyDetails } from "../../../../Shared/PrettyDetails/PrettyDetails";
import styles from "../SettingSection.module.css";
import { useContext } from "react";
import { UserContext } from "../../../../App";
import { UserType } from "../../../../Api/Interfaces";
import { UserService } from "../../../../Api/User";
import { UseLoading } from "../../../../Shared/Loading/UseLoading";

export const Payment = () => {
    const user = useContext(UserContext);
    const loader = UseLoading();

    const goToStripePaymentDashboard = async () => {
        loader.startLoading();
        const url = await UserService.PaymentPortalAsync();
        loader.stopLoading();
        window.open(url, "_self");
    }

    const hasPaymentDashboard = user?.type === UserType.normal;

    return (
        <PrettyDetails
            text={"Betaling"}
            icon={<DollarSign />}
            extraTopContent={
                <>
                    {loader.loadingComponent}
                </>
            }
        >
            <div className={styles.inputSection}>
                <div className={styles.infoText}>
                    <h2>Håndter betalingsinfo</h2>
                    <p>Betaling blir håndtert gjennom Stripe. For spørsmål rundt betaling, send en mail til support@repset.no</p>
                </div>

                {hasPaymentDashboard ? (
                    <button onClick={goToStripePaymentDashboard} disabled={loader.isLoading} className={styles.settingsBtn}>
                        Gå til betaling
                        </button>
                ) : (
                    <p style={{ userSelect: "none" }}>{user?.type === UserType.admin ? "Admin" : "Test"} brukere har ikke betalingsside</p>
                )}
            </div>
        </PrettyDetails>
    );
};
