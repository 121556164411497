/** @format */

import { CircleUser, Clock, Dumbbell } from "lucide-react";
import styles from "../../SharedStyles/ListItem.module.css";
import { IVisiblePlan, StateManContext } from "../../StateMan";
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

export interface IPlanListProps {
    plans: IVisiblePlan[];
}

export const PlanList = (props: IPlanListProps) => {
    const state = useContext(StateManContext);
    const navigate = useNavigate();

    const editPlan = (plan: IVisiblePlan) => {
        navigate(`/plans/edit/${plan.id}`);
    };

    if (props.plans.length === 0) {
        return (
            <div className={styles.noPlansContainer}>
                <p>Ingen plan matcher filtreringen. Opprett en plan med |+ Opprett plan| knappen til venstre i menyen</p>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <ul>
                {props.plans.map((plan, i) => (
                    <li style={{ width: "100%" }} key={i}>
                        <button className={`${styles.listBtn}`} onClick={() => editPlan(plan)}>
                            <button
                                className={`${styles.favoriteBtn} ${plan.favorite ? styles.favoriteBtnSelected : ""}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    state?.changePlanFavorite(plan.id);
                                }}
                            >
                                {plan.favorite ? <IoHeart /> : <IoHeartOutline />}
                            </button>

                            <div className={styles.leftSide}>
                                <div className={styles.imageContainer}>
                                    <Dumbbell size={32} />


                                    <div title="Antall øvelser i planen" className={styles.countText}>{plan.exerciseCount}</div>
                                </div>

                                <div className={styles.primaryText}>
                                    <h2>{plan.name}</h2>
                                    <p>{plan.description?.length > 0 ? plan.description : "Ingen beskrivelse"}</p>
                                </div>
                            </div>

                            <div className={styles.rightSide}>
                                <div className={styles.secondaryText}>
                                    <p style={{ color: plan.customer && "#000" }}>
                                        {plan.customer?.name ?? "Ingen kunde"}
                                        <CircleUser />
                                    </p>
                                    <p title="Sist endret">
                                        {new Date(plan.updatedAt).toLocaleDateString()}
                                        <Clock />
                                    </p>
                                </div>
                            </div>
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};
