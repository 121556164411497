import { FileRole } from "./FileRoleEnum";

export enum UserType {
    normal = 0,
    test = 1,
    admin = 2,
}

export interface IUser {
    id: number;
    name: string;
    icon: string;
    created: Date;
    email: string;
    isEmailValidated: boolean;
    isPaid: boolean;
    isPayingCustomer: boolean;
    type: UserType;
}

export interface IAdminUser {
    id: number;
    name: string;
    created: Date;
    email: string;
    quantity: number;
    isEmailManuallyValidated: boolean;
    isPaid: boolean;
    isPayingCustomer: boolean;
    customPrice: string;
    type: UserType;   
}

export interface IPaymentStatus {
    needToPay: boolean;
    message: string;
    humanMessage: string;
}

export interface IApiData {
    statusCode: number;
    content: any;
    ok: boolean;
}

export interface ITag {
    name: string;
    id: number;
    type: ITagType | null;
}

export interface ITagType {
    name: string;
    id: number;
}

export interface IExercise {
    id: number;
    name: string;
    description: string;
    favorite: boolean;
    files: IFile[];
    tags: ITag[];
    searchWords: string[];
}

export interface IFile {
    id: number;
    exerciseId: number;
    fileId: string;
    fileName: string;
    ordering: number;
    role: FileRole;
    publicUrl: string;
    type: string;
    extension: string;
}

export interface IBasicStatistics {
    numberOfSpecialUsers: number;
    numberOfNormalUsers: number;

    numberOfTags: number;
    numberOfExercises: number;
    numberOfFiles: number;

    numberOfPlans: number;
    numberOfCustomers: number;
}

export interface ICustomer {
    id: number;
    name: string;
    email: string;
    updatedAt: Date;
    createdAt: Date;
    planCount: number;
}

export interface IPlan {
    id: number;
    name: string;
    description: string;
    favorite: boolean;
    publicId: string;
    exerciseCount: number;
    updatedAt: Date;
    createdAt: Date;
    customerId?: number;
}

export interface IRawPlanExerciseData {
    comment: string;
    reps: string;
    sets: string;
    weight: string;
}

export interface IInsertPlanExercise extends IRawPlanExerciseData {
    exerciseId: number;
    ordering: number;
}

export interface IPlanExercise extends IInsertPlanExercise {
    planId: number;
    id: number;
}

export interface ICustomPrice {
    info: string;
    priceText: string;
}
