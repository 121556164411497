/** @format */

import { KeyboardEvent, useState } from "react";
import styles from "./PrettyDetails.module.css";
import { ChevronDown, ChevronUp } from "lucide-react";

export interface IPrettyDetailsProps {
    icon?: React.ReactNode;
    extraTopContent?: React.ReactNode;
    text: string;
    children: React.ReactNode;
    detailStyles?: React.CSSProperties;
}

export const PrettyDetails = (props: IPrettyDetailsProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const onKeyUp = (e: KeyboardEvent<HTMLDetailsElement>) => {
        const target: any = e.target;
        const element: HTMLDetailsElement = target;

        if (e.key === "Enter" && e.target === document.activeElement) {
            element.open = !element.open;
        }
    };

    return (
        <details
            open={isOpen}
            onKeyUp={onKeyUp}
            tabIndex={0}
            onToggle={() => {
                setIsOpen(!isOpen);
            }}
            style={props.detailStyles}
            className={styles.details}
        >
            <summary tabIndex={-1} className={styles.summary}>
                <div className={styles.title}>
                    {props.icon}
                    <span>{props.text}</span>
                </div>

                <div className={styles.topContent}>
                    {props.extraTopContent}

                    {isOpen ? (
                        <div className={styles.chevronDown}>
                            <ChevronUp size={18} />
                        </div>
                    ) : (
                        <div className={styles.chevronUp}>
                            <ChevronDown size={18} />
                        </div>
                    )}
                </div>
            </summary>

            <div className={styles.content}>{props.children}</div>
        </details>
    );
};
