/** @format */

import { useEffect, useState } from "react";
import { IBasicStatistics } from "../../Api/Interfaces";
import { StatisticsService } from "../../Api/Statistics";
import "./StatisticsView.css";

export const StatisticsView = () => {
    const [stats, setStats] = useState<IBasicStatistics | null>(null);
    const [lastUpdated, setlastUpdated] = useState<Date | null>(null);

    const loadStatisticsAsync = async () => {
        const statistics = await StatisticsService.GetBasicDataAsync();
        setStats(statistics);
        setlastUpdated(new Date());
    };

    useEffect(() => {
        loadStatisticsAsync();
    }, []);

    const monthNames = [
        "Janura",
        "Februar",
        "Mars",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Desember",
    ];

    let statisticsNiceView: any = null;
    if (stats && lastUpdated) {
        statisticsNiceView = (
            <div>
                <p>
                    Siste oppdatert <b>{lastUpdated.toLocaleString("en-GB").toString()}:{lastUpdated.getMilliseconds()}</b>
                </p>
                <p>Antall planer: {stats.numberOfPlans}</p>
                <br />
                <p>Admin og test brukere: {stats.numberOfSpecialUsers}</p>
                <p>Vanlige brukere: {stats.numberOfNormalUsers}</p>
                <br />
                <p>Antall øvelser: {stats.numberOfExercises}</p>
                <p>Antall filer for øvelser: {stats.numberOfFiles}</p>
                <br />
                <p>Antall tagger: {stats.numberOfTags}</p>
                <p>Antall kunder: {stats.numberOfCustomers}</p>
            </div>
        );
    }

    return (
        <div className="AdminListSimpleView" style={{ textAlign: "center" }}>
            <div className="info">
                <h1>Statistikk</h1>
                <p>Dette er basic statistikk om hva som skjer i systemet. Kan enkelt bli utvidet senere. For betalingsstatistikk, sjekk <a href="https://dashboard.stripe.com/dashboard" rel="noreferrer">Stripe dashboard</a></p>

                <button
                    className="Button Primary"
                    onClick={() => {
                        loadStatisticsAsync();
                    }}
                >
                    Oppfrisk statistikk fra database
                </button>
            </div>

            {statisticsNiceView}
        </div>
    );
};
