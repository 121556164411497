import { IApiData } from "./Interfaces";

const isProd = process.env.NODE_ENV === "production"

const config = {
    baseUrl: process.env.API_URL ? process.env.API_URL : isProd ? "https://api.repset.no" : "/api"
}

export function CreateUrl(url: string): string {
    const fullUrl = `${config.baseUrl}/${url}`
    return fullUrl
}

export enum DownloadType {
    Json,
    File,
    Text,
}

const BackEndFetch = async (verb: string, url: string, isFileUpload: boolean, downloadType: DownloadType, data?: any) => {
    const finalUrl = CreateUrl(url)

    let headers: any = undefined
    if (!isFileUpload) {
        headers = { "Accept": "*/*", "Content-Type": "application/json" }
    }

    try {
        const response = await fetch(finalUrl, {
            credentials: "include",
            method: verb.toUpperCase(),
            headers,
            body: isFileUpload ? data : JSON.stringify(data),
        });

        let content: any = {}
        try {
            switch (downloadType) {
                case DownloadType.Json:
                    content = await response.json();
                    break;

                case DownloadType.File:
                    content = await response.blob();
                    break;

                case DownloadType.Text:
                    content = await response.text();
                    break;
                
                default:
                    content = await response.text();
                    break;
            }
        } catch(error) {}

        return {
            ok: response.ok,
            statusCode: response.status,
            content,
        }
    } catch (error: any) {
        if (typeof error === "object") {
            alert("Kunne ikke nå server. Sjekk internett tilkoblingen din og prøv igjen senere, og hvis problemet vedvarer, kontakt RepSet");
        }

        console.warn(`${verb} failed with data: ${data}. URL: ${url}. The following error message: ${JSON.stringify(error)}`)

        return {
            ok: false,
            statusCode: 600,
            content: { message: `Client Side fetch failed with the following error: ${error}` }
        }
    }
}

export const Connector = {
    async getAsync(url: string): Promise<IApiData> {
        const response = await BackEndFetch("get", url, false, DownloadType.Json)
        return response;
    },

    async postAsync(url: string, data: any): Promise<IApiData> {
        const response = await BackEndFetch("post", url, false, DownloadType.Json, data)
        return response;
    },

    async putAsync(url: string, data: any): Promise<IApiData> {
        const response = await BackEndFetch("put", url, false, DownloadType.Json, data)
        return response;
    },

    async deleteAsync(url: string): Promise<IApiData> {
        const response = await BackEndFetch("delete", url, false, DownloadType.Json)
        return response;
    }
}

export const FileConnector = {
    async getAsync(url: string): Promise<IApiData> {
        const response = await BackEndFetch("get", url, false, DownloadType.File)
        return response;
    },

    async postAsync(url: string, data: any): Promise<IApiData> {
        const response = await BackEndFetch("post", url, true, DownloadType.Json, data)
        return response;
    }
}

export const TextConnector = {
    async getAsync(url: string): Promise<IApiData> {
        const response = await BackEndFetch("get", url, false, DownloadType.Text)
        return response;
    },

    async postAsync(url: string, data: any): Promise<IApiData> {
        const response = await BackEndFetch("post", url, false, DownloadType.Text, data)
        return response;
    }
}
