/** @format */

import { Mail, Save, Trash2, UserPen, UserRound, X } from "lucide-react";
import styles from "./CustomerEdit.module.css";
import { ICustomer } from "../../../../../Api/Interfaces";
import { useContext, useEffect, useState } from "react";
import { CustomerService } from "../../../../../Api/Customer";
import { StateManContext } from "../../../StateMan";
import { useLocation, useNavigate } from "react-router-dom";
import { EmailValidator } from "../../../../../Shared/EmailValidator";

export interface ICustomerEditProps {
    customer?: ICustomer;
    exitModal: () => any;
}

export const CustomerEdit = (props: ICustomerEditProps) => {
    const [name, setName] = useState(props.customer?.name ?? "");
    const [email, setEmail] = useState(props.customer?.email ?? "");
    const stateMan = useContext(StateManContext);
    const location = useLocation();
    const isCreating = location.pathname.includes("create");

    const deleteAsync = async () => {
        if (!props.customer?.id) return;

        const shouldDelete = window.confirm("Er du sikker på at du vil slette kunden? DETTE VIL SLETTE ALLE PLANER TILKNYTTET KUNDEN!");
        if (!shouldDelete) return;

        const didDelete = await CustomerService.DeleteAsync(props.customer.id);
        if (!didDelete) {
            return alert("Klarte ikke slette kunden!");
        }

        stateMan?.updateAsync();
        props.exitModal();
    };

    const saveAsync = async () => {
        var didSave = false;
        if (props.customer?.id) {
            didSave = await CustomerService.UpdateAsync(props.customer.id, name, email);
        } else {
            didSave = (await CustomerService.CreateAsync(name, email)) !== null;
        }

        if (!didSave) {
            return alert("Klarte ikke lagre kunden. Sjekk at den har et navn og gyldig e-post!");
        }

        stateMan?.updateAsync();
        props.exitModal();
    };

    const CancelAsync = async () => {
        props.exitModal();
    };

    useEffect(() => {
        setName(props.customer?.name ?? "");
        setEmail(props.customer?.email ?? "");
    }, [props.customer]);

    const isValidEmail = email.length === 0 || EmailValidator.isValidEmailAddress(email);
    const isSaveEnabled = name.length > 0 && isValidEmail;

    return (
        <div className={`${styles.section} ${styles.container}`}>
            <div className={styles.top}>
                <div className={styles.profileImg}>
                    <div className={styles.profileInner}>
                        <UserRound size={36} />
                    </div>
                </div>
            </div>

            <div className={styles.view}>
                <h2>{props.customer?.name ?? "Ny kunde"}</h2>
                <p>{props.customer?.email ?? ""}</p>
            </div>

            <form className={styles.content}>
                <hr />

                <div className={styles.edit}>
                    <div>
                        <p>
                            Navn
                            <span style={{ color: "red" }}> *</span>
                        </p>
                    </div>

                    <div className={`borderInputIcon ${styles.input}`}>
                        <UserPen />
                        <input autoFocus={isCreating} type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                </div>

                <hr />

                <div className={styles.edit}>
                    <div>
                        <p>E-post</p>
                    </div>

                    <div className={`borderInputIcon ${styles.input}`}>
                        <Mail style={{ color: isValidEmail ? undefined : "red" }} />
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </div>

                <hr />

                <div className={styles.btns}>
                    {isCreating ? (
                        <div />
                    ) : (
                        <button
                            type="button"
                            className="Button Primary Small"
                            style={{ backgroundColor: "#fbf5f5", color: "red" }}
                            onClick={(e) => {
                                e.preventDefault();
                                deleteAsync();
                            }}
                        >
                            <Trash2 size={12} />
                            Slett
                        </button>
                    )}

                    <div className={styles.rightBtns}>
                        <button
                            type="button"
                            className={`Button Secondary Small ${styles.cancelBtn}`}
                            onClick={(e) => {
                                e.preventDefault();
                                CancelAsync();
                            }}
                        >
                            Avbryt
                        </button>

                        <button
                            type="submit"
                            disabled={!isSaveEnabled}
                            className="Button Primary Small"
                            onClick={(e) => {
                                e.preventDefault();
                                saveAsync();
                            }}
                        >
                            <Save size={12} />
                            Lagre
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};
