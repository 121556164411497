
import { useEffect, useState } from "react"
import "./Loading.css"
import SpinnerImg from "./RepSetSpinner.svg"

export const Loading = () => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setIsVisible(true)
        }, 100);

    })

    return (
        <div className="fullScreenLoading" style={{ display: isVisible ? undefined : "none" }}>
            <img src={SpinnerImg} alt="load" />
        </div>
    )
}