import { useEffect, useState } from "react";


export interface IView {
    name: string;
    component: React.ReactElement,
}

export const useRouting = (views: IView[]) => {
    const [currentView, setView] = useState<IView>(views[0])

    useEffect(() => {
        if (views.length > 0) {
            setView(views[0])
        } else {
            throw new Error("View Router must have at least one view in the parameter list")
        }
    }, [])

    const set = (view: IView) => {
        setView(view)
    }

    const routingCompontent = <ul className="routerList">
        {views.map(item => (
            <li key={item.name} className={currentView.name === item.name ? "selected" : "" } onClick={() => {
                setView(item)
            }}>{item.name}</li>
        ))}
    </ul>

    return {
        allViews: views,
        currentView: currentView,
        component: routingCompontent,
        set: set,
    }
}