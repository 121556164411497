/** @format */

import { Connector, FileConnector } from "./Connector";
import { FileRole } from "./FileRoleEnum";

export enum FileType {
    image,
    video,
    text,
    unknown,
}

export const FileService = {
    async uploadAsync(file: File, role?: FileRole): Promise<string | null> {
        const data = new FormData();
        data.append("file", file);

        const roleStr = role ? `?role=${role}` : ""
        const returnData = await FileConnector.postAsync(`file/upload${roleStr}`, data);
        if (!returnData.ok) return null;
        return returnData.content.id;
    },

    async downloadAsync(id: string): Promise<File | null> {
        const returnData = await FileConnector.getAsync(`file/download/${id}`);
        if (!returnData.ok) return null;
        return returnData.content;
    },

    async deleteAsync(id: string): Promise<boolean> {
        const returnData = await Connector.deleteAsync(`file/delete/${id}`);
        return returnData.statusCode === 200;
    },

    async readURLAsync(file: File): Promise<string> {
        return new Promise((res, rej) => {
            const reader = new FileReader();

            reader.onload = (e: any) => {
                res(e.target.result);
            };

            reader.onerror = (e) => rej(e);

            reader.readAsDataURL(file);
        });
    },

    GetExtensionFromFileName(fileName: string): string {
        const extension = fileName.split('.').pop();
        return extension ?? ""
    },

    GetFileNameWithoutExtension(fileName: string): string {
        const parts = fileName.split('.')
        parts.pop()
        return parts.join()
    },

    GetFileType(fileName: string): FileType {
        const video = ["mp4", "mkv", "avi", "mov", "wmv", "flv", "webm", "mpg", "mpeg", "ts", "3gp", "m4v", "ogv", "rm", "rmvb", "vob", "divx", "mpg", "mts"];
        const image = ["jpg", "jpeg", "png", "gif", "webp", "tiff", "tif", "bmp", "svg"];
        const text = ["txt", "rtf", "doc", "docx"];

        const fileExtension = fileName.split(".").pop();
        if (!fileExtension) return FileType.unknown;

        if (image.includes(fileExtension)) return FileType.image;
        if (video.includes(fileExtension)) return FileType.video;
        if (text.includes(fileExtension)) return FileType.text;

        return FileType.unknown;
    },
};
