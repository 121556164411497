

import { Connector } from "./Connector";
import { ICustomer } from "./Interfaces";

export const CustomerService = {
    async ListAsync(): Promise<ICustomer[]> {
        const data = await Connector.getAsync("customer")
        if (!data.ok) return [];
        return data.content
    },

    async GetAsync(id: number): Promise<ICustomer | null> {
        const data = await Connector.getAsync(`customer/${id}`)
        if (!data.ok) return null;
        return data.content
    },

    async CreateAsync(name: string, email: string): Promise<number | null> {
        const data = await Connector.postAsync("customer", { name, email });
        if (!data.ok) return null;
        return data.content.id;
    },

    async UpdateAsync(id: number, name: string, email: string): Promise<boolean> {
        const data = await Connector.putAsync(`customer/${id}`, { name, email })
        return data.ok;
    },

    async DeleteAsync(id: number): Promise<boolean> {
        const data = await Connector.deleteAsync(`customer/${id}`);
        return data.ok
    }
}