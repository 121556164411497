/** @format */

import Fuse from "fuse.js";
import { ISearchItem } from "./Interfaces";

export class SearchEngine {
    private readonly options = {
        distance: 100,
        useExtendedSearch: true,
        keys: ["name", "description", "extraSearchText"],
    };

    private readonly fuse: Fuse<ISearchItem>;

    constructor(items: ISearchItem[]) {
        this.fuse = new Fuse(items, this.options);
    }

    public Search(text: string): ISearchItem[] {
        const fuseResult: any[] = this.fuse.search(text);

        for (var i = 0; i < fuseResult.length; i++) {
            fuseResult[i] = fuseResult[i].item;
        }

        return fuseResult;
    }
}
