

import { ChangeEvent } from "react"
import { FaRegTrashAlt } from "react-icons/fa";
import "./ImageUpload.css"

interface IImageUpload {
    image: string;
    onImageChanged: (image: string) => any; 
}

const fileToDataUrl = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {

        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(String(reader.result));
        }

        reader.onerror = () => {
            console.error("data url conversion error")
            resolve("");
        }

        reader.readAsDataURL(file);
    })
}

export const ImageUpload = (props: IImageUpload) => {
    const onImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target?.files && event.target.files.length > 0) {
            const fileObj = event.target.files[0]
            const dataUrl = await fileToDataUrl(fileObj);
            if (dataUrl.length > 10000000) {
                alert("Bildet er for stort");
                return;
            }
            props.onImageChanged(dataUrl);
        }
    }

    return (
        <div className="ImageUpload">
            {!props.image && (
                <label className="uploadLabelBtn">
                    <input className="uploadInput" onChange={onImageChange} type="file" accept=".jpg,.jpeg,.png,.svg,.webp,.tiff,.tif" />
                    <p>Tilpasset logo for bedrift</p>
                </label>
            )}

            {props.image && (
                <>
                    <img className="image" src={props.image} alt="uploaded" />
                    <button className="removeBtn" onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        props.onImageChanged("")
                    }}>
                        <FaRegTrashAlt size={20} />
                    </button>
                </>
            )}
        </div>
    )
}