/** @format */

import { useEffect, useRef, useState } from "react";
import "./ListFramework.css";
import { useOutsideAlerter } from "../useOutsideAlerter";

export interface IListProps {
    items: any[];
    componentRendering: (item: any, isSelected: boolean, closeItem: () => any) => React.ReactElement;
    customStyles?: React.CSSProperties;
    customRef: React.MutableRefObject<HTMLDivElement>;
}

export const ExpandList = (props: IListProps) => {
    const listElement = props.customRef;
    const [selectedItem, setSelectedItem] = useState<number>(-1);

    useOutsideAlerter(listElement, () => {
        setSelectedItem(-1);
    });

    useEffect(() => {
        setSelectedItem(-1);
    }, [props.items])

    return (
        <div className="listFramework" ref={listElement} style={props.customStyles}>
            <ul>
                {props.items.map((item, i) => (
                        <li
                            key={i}
                            onClick={() => {
                                if (selectedItem != i) {
                                    setSelectedItem(i);
                                }
                            }}
                        >
                            {props.componentRendering(item, i === selectedItem, () => {
                                setSelectedItem(-1);
                            })}
                        </li>
                ))}
            </ul>
        </div>
    );
};
