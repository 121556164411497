


import { Connector } from "./Connector";
import { IInsertPlanExercise, IPlan, IPlanExercise } from "./Interfaces";

export const PlanService = {
    async ListAsync(): Promise<IPlan[]> {
        const data = await Connector.getAsync("plan")
        if (!data.ok) return [];
        return data.content
    },

    async GetAsync(id: number): Promise<IPlan | null> {
        const data = await Connector.getAsync(`plan/${id}`)
        if (!data.ok) return null;
        return data.content
    },

    async CreateAsync(name: string, favorite: boolean, customerId?: number): Promise<number | null> {
        const data = await Connector.postAsync("plan", { name, favorite, customerId, });
        if (!data.ok) return null;
        return data.content.id;
    },

    async UpdateAsync(id: number, name: string, favorite: boolean, customerId?: number): Promise<number | null> {
        const data = await Connector.putAsync(`plan/${id}`, { name, favorite, customerId })
        if (!data.ok) return null;
        return data.content.id
    },

    async UpdateFavoriteAsync(id: number, favorite: boolean): Promise<number | null> {
        const data = await Connector.putAsync(`plan/favorite/${id}`, { favorite })
        if (!data.ok) return null;
        return data.content.id
    },

    async UpdateDescriptionAsync(id: number, description: string): Promise<number | null> {
        const data = await Connector.putAsync(`plan/description/${id}`, { description })
        if (!data.ok) return null;
        return data.content.id
    },

    async DeleteAsync(id: number): Promise<boolean> {
        const data = await Connector.deleteAsync(`plan/${id}`);
        return data.ok
    },

    // For the nested exercises under plans
    async GetPlanExercises(planId: number): Promise<IPlanExercise[]> {
        const data = await Connector.getAsync(`plan/${planId}/exercises`)
        if (!data.ok) return [];
        return data.content
    },

    async SavePlanExercises(planId: number, exercises: IInsertPlanExercise[]): Promise<boolean> {
        const data = await Connector.postAsync(`plan/${planId}/exercises`, { exercises: exercises })
        return data.ok
    },
}