/** @format */

import { KeyRound, RectangleEllipsis } from "lucide-react";
import { PrettyDetails } from "../../../../Shared/PrettyDetails/PrettyDetails";
import styles from "../SettingSection.module.css";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../App";
import { UseLoading } from "../../../../Shared/Loading/UseLoading";
import { UserService } from "../../../../Api/User";

export const Password = () => {
    const user = useContext(UserContext);
    const loader = UseLoading();

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const clearFields = () => {
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
    };

    const saveNewPassword = async () => {
        loader.startLoading();
        const success = await UserService.ChangePasswordAsync(currentPassword, newPassword);
        loader.stopLoading();

        if (success) {
            alert("Passord endret");
            clearFields();
        } else {
            alert("Klarte ikke endre passord. Sjekk at nåværende passord er riktig og prøv igjen.");
        }
    };

    const passwordMatches = newPassword === confirmPassword;
    const areButtonsVisible = currentPassword.length > 0 && newPassword.length > 0 && confirmPassword.length > 0;
    const canSave = areButtonsVisible && passwordMatches && newPassword.length >= 4 && confirmPassword.length >= 4;

    return (
        <PrettyDetails
            text={"Passord"}
            icon={<KeyRound />}
            extraTopContent={
                <div className={styles.actionButtons}>
                    {loader.loadingComponent}

                    {areButtonsVisible && (
                        <>
                            <button disabled={loader.isLoading} className={`${styles.actionBtn} ${styles.cancelBtn}`} onClick={clearFields}>
                                Avbryt
                            </button>
                            <button disabled={!canSave || loader.isLoading} className={`${styles.actionBtn} ${styles.saveBtn}`} onClick={saveNewPassword}>
                                Endre passord
                            </button>
                        </>
                    )}
                </div>
            }
        >
            <div className={styles.inputSection}>
                <div className={styles.infoText}>
                    <h2>
                        Nåværende passord<span style={{ color: "red" }}> *</span>
                    </h2>
                    <p>Det nåværende passordet på din Repset bruker</p>
                </div>

                <div className={`borderInputIcon ${styles.input}`}>
                    <KeyRound />
                    <input type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
                </div>
            </div>

            <hr />

            <div className={styles.inputSection}>
                <div className={styles.infoText}>
                    <h2>
                        Nytt passord<span style={{ color: "red" }}> *</span>
                    </h2>
                    <p>Ditt nye passord. Må være minst 4 siffer, men 8 er anbefalt</p>
                </div>

                <div className={`borderInputIcon ${styles.input}`}>
                    <RectangleEllipsis style={{ color: newPassword.length > 0 && newPassword.length < 4 ? "red" : undefined }} />
                    <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                </div>
            </div>

            <hr />

            <div className={styles.inputSection}>
                <div className={styles.infoText}>
                    <h2>
                        Bekreft nytt passord<span style={{ color: "red" }}> *</span>
                    </h2>
                    <p>Skriv inn det nye passordet en gang til</p>
                </div>

                <div className={`borderInputIcon ${styles.input}`}>
                    <RectangleEllipsis style={{ color: passwordMatches ? undefined : "red" }} />
                    <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                </div>
            </div>
        </PrettyDetails>
    );
};
