/** @format */

import { loadStripe } from "@stripe/stripe-js/pure";
import "./CustomPayment.css";
import { UserService } from "../../../Api/User";
import { UseLoading } from "../../../Shared/Loading/UseLoading";
import { ICustomPrice } from "../../../Api/Interfaces";
import { IoMdSettings } from "react-icons/io";

export interface ICustomPaymentProps {
    showSettingsPanel: () => void;
    price: ICustomPrice;
}

export const CustomPayment = (props: ICustomPaymentProps) => {
    const useLoader = UseLoading();

    const payNow = async () => {
        useLoader.startLoading();
        const stripe = await loadStripe(
            process.env.REACT_APP_STRIPE_PUBLIC_KEY ??
                "pk_test_51LQWOYJ3wOMmVBLrX6QZsbpSlJu1m1s6lxRyUupXNr01qUhSB0V5V5aUOLWIqaJTDZaDKY8TsvGSqovPH4Gjo2nO00uEp0wBwq"
        );

        const checkoutSessionId = await UserService.PaymentCustomCheckoutAsync();
        useLoader.stopLoading();

        if (!stripe) return alert("Klarte ikke laste innbetaling, prøv igjen senere");
        if (!checkoutSessionId) return alert("Klarte ikke finne innbetalingsserver, prøv igjen senere");

        stripe.redirectToCheckout({
            sessionId: checkoutSessionId,
        });
    };

    return (
        <div className="CustomPayment">
            <div className="infoBox">
                <h1>Du har fått en spesialtilpasset pris fra Repset teamet</h1>
                <h2>{props.price.priceText}</h2>
            </div>

            <div className="priceBox">
                <h3>Beskrivelse</h3>
                <p>{props.price.info}</p>
            </div>

            <div className="cta">
                <button disabled={useLoader.isLoading} onClick={props.showSettingsPanel} className="Button Secondary Centered NoPadding">
                    <IoMdSettings size={24} />
                </button>

                <button disabled={useLoader.isLoading} onClick={payNow} className="Button Primary PayNowBtn">
                    Betal nå
                </button>
            </div>
        </div>
    );
};
