/** @format */

import { useEffect, useRef, useState } from "react";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import "./MainList.css";
import "./ContextMenu.css";
import { IItem, IListProps } from "./Interfaces";

export const List = (props: IListProps) => {
    const contextMenuRef = useRef<HTMLDivElement>(null);
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
    const [contextMenuItem, setContextMenuItem] = useState<IItem | null>(null);
    const [openEditorId, setOpenEditorId] = useState<number>(-1);
    const editFormRef = useRef<HTMLFormElement>(null);
    const dragHelperRef = useRef<HTMLDivElement>(null);
    const mainListRef = useRef<HTMLUListElement>(null);
    const dragDropSectionRef = useRef<HTMLLIElement>(null);

    const shouldDisplayContextButton = props.handlers || props.onDelete || props.onEdit;

    const showContextMenu = (pageX: number, pageY: number) => {
        const element = contextMenuRef.current;
        if (!element) return;
        if (!shouldDisplayContextButton) return;

        element.style.left = pageX - 250 + "px";
        if (pageY + 300 > window.screen.height) {
            element.style.top = pageY - 400 + "px";
        } else {
            element.style.top = pageY + "px";
        }

        if (!isContextMenuOpen) {
            setIsContextMenuOpen(true);
        }
    };

    const showDragHelper = (pageX: number, pageY: number) => {
        /*
        if (!props.droppable || !dragHelperRef) return;
        if (!dragHelperRef.current) return;

        dragHelperRef.current.style.display = "block";
        dragHelperRef.current.style.top = pageY + "px";
        dragHelperRef.current.style.left = pageX - 45 + "px";
        */
    };

    const hideDragHelper = () => {
        /*
        if (!props.droppable || !dragHelperRef) return;
        if (!dragHelperRef.current) return;

        dragHelperRef.current.style.display = "none";
        */
    };

    const hideContextMenu = (e: MouseEvent) => {
        setIsContextMenuOpen(false);
    };

    const resize = (gotHeight: number) => {
        if (!mainListRef?.current) return;
        const ref = mainListRef.current;
        ref.style.maxHeight = `${gotHeight - ref.offsetTop - 12}px`;

        if (!dragDropSectionRef?.current) return;
        const dref = dragDropSectionRef.current;
        dref.style.maxHeight = `${gotHeight - ref.offsetTop - 24}px`
    };

    const resizeObserver = new ResizeObserver((entries) => {
        const height = entries[0].target.clientHeight;
        resize(height);
    });

    useEffect(() => {
        resize(window.outerHeight - 10); // Little hack to use -10 here. Todo: fix this
        resizeObserver.observe(document.body);
    }, []);

    useEffect(() => {
        document.addEventListener("click", hideContextMenu);

        return () => {
            document.removeEventListener("click", hideContextMenu);
        };
    }, [hideContextMenu]);

    const ClearEditForm = () => {
        if (editFormRef && editFormRef.current) {
            const sender: any = editFormRef.current;
            const inputs: any[] = sender.querySelectorAll("input:not(.formInput)");
            inputs.forEach((input) => {
                input.value = "";
            });

            setOpenEditorId(-1);
        }
    };

    // Not the best date check in the world, but it works for our format (gb). Could use moment.js or something like it, but not interested in including such a big library for this one feature.
    const isNameADate = (name: string): boolean => {
        return name.includes("/") && name.includes(",") && name.includes(" ") && name.includes(":");
    };

    return (
        <div className="SharedList">
            <div ref={dragHelperRef} className="dragHelper" />
            <div className="contextMenu" ref={contextMenuRef} style={{ display: isContextMenuOpen ? "block" : "none" }}>
                <ul>
                    {props.handlers?.map((item) => {
                        let name = item.name;

                        if (item.dynamicName && contextMenuItem) {
                            name = item.dynamicName(contextMenuItem);
                        }

                        return (
                            <li
                                key={`create${item.id}${contextMenuItem?.id}`}
                                onClick={() => {
                                    if (contextMenuItem) {
                                        item.callback(contextMenuItem);
                                    }
                                }}
                            >
                                <p style={{ color: item.color }}>{name}</p>
                            </li>
                        );
                    })}

                    {props.crudComponent && props.onEdit ? (
                        <li
                            key={`edit${contextMenuItem?.id}`}
                            onClick={() => {
                                if (contextMenuItem) {
                                    setOpenEditorId(contextMenuItem.id);
                                }
                            }}
                        >
                            <p>Rediger</p>
                        </li>
                    ) : null}

                    {props.onDelete ? (
                        <li
                            key={`delete${contextMenuItem?.id}`}
                            onClick={() => {
                                if (contextMenuItem && props.onDelete) {
                                    props.onDelete(contextMenuItem.id);
                                }
                            }}
                        >
                            <p style={{ color: "#D64545" }}>Slett</p>
                        </li>
                    ) : null}
                </ul>
            </div>

            <ul className={`mainList ${props.gridBased ? "ulGrid" : undefined}`} ref={mainListRef}>
                
                {props.droppable ? (
                    <li
                        className="ListItem topDrop"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={() => {
                            if (props.droppable) props.droppable(-1);
                        }}
                        ref={dragDropSectionRef}
                        style={{ height: props.items.length > 0 ? "60px" : "300vh" }}
                    >
                        <p>Dra øvelser hit</p>
                    </li>
                ) : null}

                {props.items.map((item) => {
                    if (item.id === openEditorId) {
                        return (
                            <form
                                key={item.id}
                                onKeyDown={(e) => {
                                    if (e.key === "Escape") {
                                        setOpenEditorId(-1);
                                    }
                                }}
                                ref={editFormRef}
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                <div className="ListItem" style={{ cursor: props.onClick ? "pointer" : "default" }}>
                                    <div className="componentHolder">
                                        {props.crudComponent ? props.crudComponent(item) : null}
                                    </div>

                                    <input
                                        onClick={() => {
                                            if (props.onEdit) {
                                                props.onEdit(editFormRef.current);
                                                ClearEditForm();
                                            }
                                        }}
                                        type="submit"
                                        style={{ display: "none" }}
                                        value="enterSubmit"
                                    />

                                    <input name="id" style={{ display: "none" }} value={item.id} readOnly />
                                    <div className="btnGroup">
                                        <button
                                            className="Secondary Small Button"
                                            onClick={() => {
                                                setOpenEditorId(-1);
                                            }}
                                        >
                                            Avbryt
                                        </button>

                                        <button
                                            onClick={() => {
                                                if (props.onCreate) {
                                                    props.onCreate(editFormRef.current);
                                                    ClearEditForm();
                                                }
                                            }}
                                            className="Secondary Small Button"
                                            value="Lagre ny"
                                        >
                                            Lagre ny
                                        </button>

                                        <button
                                            onClick={() => {
                                                if (props.onEdit) {
                                                    props.onEdit(editFormRef.current);
                                                    ClearEditForm();
                                                }
                                            }}
                                            className="Primary Small Button NoShadow"
                                            value="Lagre"
                                        >
                                            Lagre
                                        </button>

                                        <input
                                            type="submit"
                                            style={{ display: "none" }}
                                            onClick={() => {
                                                if (props.onEdit) {
                                                    props.onEdit(editFormRef.current);
                                                    ClearEditForm();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </form>
                        );
                    } else {
                        return (
                            <li
                                draggable={props.draggable ? true : false}
                                onDragStart={() => {
                                    if (props.draggable) props.draggable(item.id);
                                }}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={() => {
                                    if (props.droppable) props.droppable(item.id);
                                }}
                                onDragEnter={(e) => {
                                    showDragHelper(e.pageX, e.pageY);
                                }}
                                onDragLeave={(e) => {
                                    hideDragHelper();
                                }}
                                className="ListItem"
                                style={{ cursor: props.onClick ? "pointer" : undefined }}
                                onContextMenu={(e) => {
                                    e.preventDefault();
                                    setContextMenuItem(item);
                                    showContextMenu(e.pageX, e.pageY);
                                }}
                                id={`SharedListItemNr${item.id}`}
                                key={item.id}
                                onClick={() => {
                                    if (props.onClick) {
                                        props.onClick(item);
                                    }
                                }}
                            >
                                <div
                                    className="leftSide"
                                    style={{ display: props.onlyShowViewComponent === true ? "none" : undefined }}
                                >
                                    <div
                                        style={{
                                            marginRight:
                                                props.customViewComponent && props.customViewComponent(item) !== null
                                                    ? "12px"
                                                    : undefined,
                                        }}
                                    >
                                        <h2 style={{ color: isNameADate(item.name) ? "#829AB1" : "#000" }}>{item.name}</h2>
                                        <p>{item.description}</p>
                                    </div>
                                </div>

                                {props.customViewComponent && props.onlyShowViewComponent
                                    ? props.customViewComponent(item)
                                    : null}

                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    {props.customViewComponent && !props.onlyShowViewComponent
                                        ? props.customViewComponent(item)
                                        : null}

                                    {(!item.extraTextTop || item.extraTextTop?.length === 0) &&
                                    (!item.extraTextBottom || item.extraTextBottom?.length === 0) &&
                                    !shouldDisplayContextButton ? null : (
                                        <div className="rightSide">
                                            <div>
                                                <p>{item.extraTextTop}</p>
                                                <p>{item.extraTextBottom}</p>
                                            </div>

                                            <button
                                                style={{ display: shouldDisplayContextButton ? undefined : "none" }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setContextMenuItem(item);
                                                    showContextMenu(e.pageX, e.pageY);
                                                }}
                                            >
                                                <IoEllipsisVerticalSharp />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </li>
                        );
                    }
                })}

            </ul>
        </div>
    );
};
