
import { CreateUrl, FileConnector, TextConnector } from "./Connector";
import { IRawPlanExerciseData } from "./Interfaces";

export const PlanRenderService = {
    async GetPlanHtmlAsync(publicPlanId: string): Promise<string | null> {
        const data = await TextConnector.getAsync(`planrender/${publicPlanId}`)
        if (!data.ok) return null;

        return data.content
    },

    async GetExerciseAsync(publicPlanId: string, exerciseId: number): Promise<string | null> {
        const data = await TextConnector.getAsync(`planrender/${publicPlanId}/${exerciseId}`)
        if (!data.ok) return null;

        return data.content
    },

    async GetUnfinishedExerciseAsync(exerciseId: number, data: IRawPlanExerciseData): Promise<string | null> {
        const response = await TextConnector.postAsync(`planrender/unfinished/exercise/${exerciseId}`, data)
        if (!response.ok) return null;

        return response.content
    },

    async ShareWithEmailAsync(publicPlanId: string, emailAddress: string, description: string): Promise<string | null> {
        const response = await TextConnector.postAsync(`planrender/${publicPlanId}/email`, { email: emailAddress, description: description })
        if (!response.ok) return null;

        return response.content
    },

    GeneratePdfUrl(publicPlanId: string, size: number): string {
        const url = CreateUrl(`planrender/${publicPlanId}/pdf/${size}`)
        return url;
    },

    GenerateEmailPreviewUrl(publicPlanId: string): string {
        const url = CreateUrl(`planrender/${publicPlanId}/emailcontent`)
        return url;
    },

    async GeneratePdfAsync(publicPlanId: string, size: string): Promise<File | null> {
        const response = await FileConnector.getAsync(`planrender/${publicPlanId}/pdf/${size}`)
        if (!response.ok) return null;

        return response.content
    },
}