
import { useContext } from "react";
import { IVisiblePlan, StateManContext } from "../StateMan";
import { usePlanFilter } from "./Filters/UsePlanFilter"
import { PlanList } from "./PlanList/PlanList";
import styles from "./Plans.module.css"
import { Startup } from "../../Startup/Startup";

export const Plans = () => {
    const state = useContext(StateManContext);
    const planFilter = usePlanFilter(state?.plans ?? []);

    return (
        <div className={styles.plans}>
            <Startup />

            {planFilter.element}

            <PlanList plans={planFilter.filteredPlans} />
        </div>
    )
}