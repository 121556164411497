/** @format */

import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import "./UseModal.css";

type callback = () => any;

export const useModal = (title: string, enableCloseOnEscape: boolean = true, onStateChange?: (isOpen: boolean) => void) => {
    const [isOpen, setIsOpen] = useState(false);
    const [customComponent, setCustomComponent] = useState<React.ReactElement>(<></>);
    const [modalStyling, setModalStyling] = useState<React.CSSProperties>();
    const [onCloseCallbacks, setOnCloseCallbacks] = useState<callback[]>([]);

    const handleKeyPress = (e: KeyboardEvent) => {
        if (e.key === "Escape" && enableCloseOnEscape) {
            close();
        }
    };

    useEffect(() => {
        document.addEventListener("keyup", handleKeyPress);
        return () => {
            document.removeEventListener("keyup", handleKeyPress);
        };
    }, []);

    const StateChange = () => {
        if (onStateChange) {
            onStateChange(isOpen);
        }
    };

    const close = () => {
        onCloseCallbacks.forEach(callback => {
            callback();
        });

        StateChange();
        setIsOpen(false);
    };

    const open = (customComponent: React.ReactElement, modalStyling?: React.CSSProperties) => {
        setModalStyling(modalStyling);
        setCustomComponent(customComponent);
        StateChange();
        setIsOpen(true);
    };

    const onClose = (callback: () => any) => {
        setOnCloseCallbacks([...onCloseCallbacks, callback])
    }

    let mainComponent = customComponent;
    mainComponent = React.cloneElement(customComponent, {
        ...customComponent.props,
        modal: {
            isOpen,
            open,
            close,
            onClose,
            StateChange,
        },
    });

    let component = <></>;
    if (isOpen) {
        component = (
            <div className="UseModal">
                <div className="innerModal" style={modalStyling}>
                    <header className="modalHeader">
                        <div>
                            <p>{title}</p>
                        </div>
                        <button onClick={close}>
                            <IoClose />
                        </button>
                    </header>

                    <div className="modalWrapper">{mainComponent}</div>
                </div>
            </div>
        );
    }

    return {
        open,
        close,
        component,
        onClose,
    };
};
