
import { useState } from 'react';
import { Sidebar } from '../Sidebar/Sidebar';
import styles from './Home.module.css';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { MenuItems } from '../Sidebar/MenuItems';
import { Header } from '../Header/Header';

export interface IHomeProps {
}

export const Home = (props: IHomeProps) => {
    const location = useLocation();
    const navigate = useNavigate();

    const currentMenuItem = MenuItems.find(m => m.link === location.pathname) ?? MenuItems[0]

    return (
        <div className={styles.Home}>
            <Sidebar menuItem={currentMenuItem} />

            <div className={styles.rightside}>
                <Header menuItem={currentMenuItem} />

                <div className={styles.contentWrapper}>
                    <div className={styles.content}>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    )
}