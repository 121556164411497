
import { useContext } from "react";
import { IMenuItem } from "../Sidebar/IMenuItem"
import styles from "./Header.module.css"
import { UserContext } from "../../../App";

export interface IHeaderProps {
    menuItem: IMenuItem;
}

export const Header = (props: IHeaderProps) => {
    const user = useContext(UserContext);

    return (
        <header className={styles.header}>
            <h2 className={styles.title}>{props.menuItem.text}</h2>

            <div className={styles.profile}>
                {user?.icon && (
                    <img src={user?.icon} />
                )}

                <div>
                    <h3>{user?.name}</h3>
                    <p>{user?.email}</p>
                </div>
            </div>
        </header>
    )
}