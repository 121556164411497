/** @format */

import { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { ISearch, ISearchItem } from "./Interfaces";
import { SearchEngine } from "./SearchEngine";
import "./Search.css";

export const UseSearch = (items: ISearchItem[], gridBased?: boolean): ISearch => {
    const [searchText, setSearchText] = useState<string>("");
    const [returnedItems, setReturnedItems] = useState<ISearchItem[]>([]);

    const clear = () => {
        setSearchText("");
        setReturnedItems(items);
    }

    const refresh = (items: ISearchItem[]) => {
        SearchForResults(searchText)
    }

    const SearchForResults = (text: string) => {
        setSearchText(text);
        if (text.length === 0) {
            return setReturnedItems(items)
        }

        const searchService = new SearchEngine(items)
        const filteredItems = searchService.Search(text)
        setReturnedItems(filteredItems)
    }

    useEffect(() => {
        setReturnedItems(items);
    }, [items])

    const component = (
        <div className={`UseSearch ${gridBased ? "gridBased" : undefined}`}>
            <IoSearch />
            <input
                placeholder="Søk"
                value={searchText}
                onChange={e => {
                    SearchForResults(e.target.value)
                }}
            />
        </div>
    );

    return {
        clear,
        refresh,
        component,
        searchText,
        returnedItems,
    };
};
